import { useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SectionTitle, SectionUploadLarge } from 'ui';
import { Input } from 'ui/inputs';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainer } from 'views';
import { Error } from './OrgProfile.styled';
import { useNavigate } from 'react-router';
import createValidationSchema from './validationSchema';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';
import {
  CHILD, //PARENT
} from 'constants/organizationsType';
import { IOrgProfileFormValues } from 'types';
import { getOrganizationRequestBody } from 'utils/apiHelpers';
import {
  createMaster,
  updateOrganization,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getCreateProfileError,
  getOrganizationId,
  getOrganizationLevel,
  getOrganizationValues,
} from 'reduxStore/createProfile/createProfileSelectors';
import {
  IOrganizationProfileRequestBody,
  IUpdateOrganizationBody,
} from 'types/api.type';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { setOrganizationValues } from 'reduxStore/createProfile/createProfileSlice';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';

export type ButtonsOrgTypeStatus = 'active' | 'inactive';

const OrgProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const organizationLevel = useAppSelector(getOrganizationLevel);
  const organizationID = useAppSelector(getOrganizationId);
  const organizationValues = useAppSelector(getOrganizationValues);
  const mspId = useAppSelector(getMainOrganizationID);
  const error = useAppSelector(getCreateProfileError);

  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  const [base64Image, setBase64Image] = useState(
    organizationValues?.icon || ''
  );
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };
  const setInitialValues = (): IOrgProfileFormValues => {
    const values = {
      name: organizationValues?.name ?? '',
      admins: organizationValues?.admins ?? [],
      street: organizationValues?.address.street ?? '',
      city: organizationValues?.address.city ?? '',
      state: organizationValues?.address.state ?? '',
      zipCode: organizationValues?.address.zip_code ?? '',
    };
    if (organizationLevel === CHILD) {
      const margin = organizationValues?.margin?.toString() ?? '';
      return { ...values, margin };
    }
    return values;
  };

  const handleSubmit = async (values: IOrgProfileFormValues) => {
    const body = getOrganizationRequestBody(values, {
      logo: base64Image,
      id: organizationID, // for update organization
      ...(organizationLevel === CHILD && { mspId }),
    });

    try {
      //TODO: need to remove it from here, this page is only for creating, not for updating
      if (organizationID) {
        await dispatch(
          updateOrganization(body as IUpdateOrganizationBody)
        ).unwrap();
      } else {
        await dispatch(
          createMaster(body as IOrganizationProfileRequestBody)
        ).unwrap();
      }
      await dispatch(retrieveAccountInfo(null)).unwrap();
      dispatch(setOrganizationValues(body));

      navigate(ROUTES.TEAM_MEMBERS_CONFIG);
    } catch (error) {
      return;
    }
  };

  return (
    <Box component="section" position="relative">
      <SectionTitle>Organization Profile</SectionTitle>
      {error && <Error>{error}</Error>}
      <Formik
        initialValues={setInitialValues()}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="row" gap="15vw" marginTop="46px">
              <Box>
                <Grid container spacing="38px" width="100%" maxWidth="630px">
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="name"
                      label="Company Name"
                      helperText={<ErrorMessage name="name" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="street"
                      label="Address"
                      helperText={<ErrorMessage name="street" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="city"
                      label="City"
                      helperText={<ErrorMessage name="city" />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="state"
                      label="State"
                      helperText={<ErrorMessage name="state" />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="zipCode"
                      label="Zip code"
                      helperText={<ErrorMessage name="zipCode" />}
                    />
                  </Grid>
                </Grid>
              </Box>
              <SectionUploadLarge
                handleUpload={handleUpload}
                onDelete={handleDeleteImage}
                imageUrl={base64Image}
                error={imageError}
                title="Company Logo"
              />
            </Box>
            <Box marginTop="47px">
              <ButtonsContainer
                titleButton="Next"
                type="submit"
                onClickBack={handleLogout}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default OrgProfile;
