import { ActionsDropdown, IconButton, Search } from 'ui';
import { LogoBox, WrapperHeader } from './HeaderWithSearch.styled';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { getAdminInfo } from 'reduxStore/organization/organizationSelectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import { IActionsProps } from 'types/selectItem.type';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const HeaderWithSearch = () => {
  const admin = useAppSelector(getAdminInfo);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  const actions: IActionsProps[] = [
    {
      icon: <SettingsOutlinedIcon fontSize="small" />,
      title: 'Settings',
      onClick: () => {
        navigate(ROUTES.ACCOUNT_SETTINGS);
      },
    },
    {
      icon: <LogoutIcon fontSize="small" />,
      title: 'Logout',
      onClick: handleLogout,
    },
  ];

  return (
    <WrapperHeader>
      <Search />
      <IconButton>
        <NotificationsNoneOutlinedIcon fontSize="medium" />
      </IconButton>
      <ActionsDropdown actions={actions}>
        {admin?.icon ? (
          <LogoBox src={admin.icon} />
        ) : (
          <AccountCircleOutlinedIcon fontSize="medium" />
        )}
      </ActionsDropdown>
    </WrapperHeader>
  );
};

export default HeaderWithSearch;
