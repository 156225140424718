import React, { useState } from 'react';
import { Box, Menu } from '@mui/material';
import {
  ActionMenuItem,
  CustomIconButton,
  OrganizationElementWrapper,
  Title,
} from './OrganizationElement.styled';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

interface IOrganizationElement {
  orgName: string;
  onDisconnect: () => void;
}

const OrganizationElement = ({
  orgName,
  onDisconnect,
}: IOrganizationElement) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <OrganizationElementWrapper>
      <Title>{orgName}</Title>
      <Box position="relative" display="inline-block">
        <CustomIconButton onClick={handleClick}>
          <MoreVertOutlinedIcon sx={{ fontSize: '14px' }} />
        </CustomIconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom', // Align the top of the menu with the bottom of the button
            horizontal: 'center', // Center the menu horizontally
          }}
          transformOrigin={{
            vertical: 'top', // Align the bottom of the menu with the top of the button
            horizontal: 'center', // Center the menu horizontally
          }}
        >
          <ActionMenuItem
            onClick={e => {
              e.stopPropagation();
              onDisconnect();
            }}
          >
            Disconnect
          </ActionMenuItem>
        </Menu>
      </Box>
    </OrganizationElementWrapper>
  );
};

export default OrganizationElement;
