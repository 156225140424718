import { Box, BoxProps, styled } from '@mui/material';

export const WrapperEDR = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  paddingTop: '30px',
  gap: '50px',
}));

export const WrapperDataBoxes = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
