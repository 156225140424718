import { Box } from '@mui/material';
import { Outlet } from 'react-router';
import { Logo } from 'ui';
import { LogoLayoutWrapper } from './LogoLayoutPage.styled';

const LogoLayoutPage = () => {
  return (
    <LogoLayoutWrapper>
      <Box>
        <Logo />
      </Box>
      <Outlet />
    </LogoLayoutWrapper>
  );
};

export default LogoLayoutPage;
