import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { COLORS } from 'theme/colors.const';

interface IIconButtonProps extends IconButtonProps {
  width?: string;
  borderradius?: string;
  bordersize?: string;
  iscolor?: string;
  isuser?: 'true';
  isdimmedonhover?: 'true';
}

//TODO: Possibly needs more work
export const StyledIconButton = styled(IconButton)<IIconButtonProps>(
  ({
    theme,
    width,
    borderradius,
    bordersize,
    iscolor,
    isuser,
    isdimmedonhover,
  }) => ({
    color: isuser ? theme.palette.common.white : theme.palette.primary.main,
    width: width ? width : '57px',
    height: width ? width : '57px',
    padding: '0px',
    border: bordersize ? `${bordersize} solid` : '1px solid',
    borderColor:
      iscolor === 'true'
        ? 'none'
        : isuser
          ? '#AAC2FF'
          : theme.palette.info.main,
    borderRadius: borderradius ? borderradius : '50%',
    backgroundColor:
      iscolor === 'true'
        ? '#ACE1FF'
        : isuser
          ? theme.palette.primary.main
          : theme.palette.background.paper,

    '&::before': {
      content: '""',
      position: 'absolute',
      borderRadius: borderradius ? borderradius : '50%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)', // Dim color overlay
      transition: 'opacity 0.3s ease',
      pointerEvents: 'none', // Make sure the overlay doesn't block interactions
      opacity: 0, // Initially invisible
    },

    // Apply the dim effect on hover
    '&:hover::before': {
      opacity: isdimmedonhover ? 1 : 0, // Show the dim effect on hover
    },
  })
);

export const TooltipWrapper = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: '94px', // Fixed width for the tooltip
    backgroundColor: COLORS.white, // White background
    color: theme.palette.red.contrastText,
    fontSize: 12,
    borderRadius: '6px',
    display: 'flex', // Center content vertically
    alignItems: 'center', // Align content in the center
    justifyContent: 'center',
    transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.white, // Match arrow color with tooltip background
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
    {
      marginTop: '-2px',
    },
}));
