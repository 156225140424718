import {
  Divider,
  IconButton,
  IconButtonProps,
  MenuItem,
  MenuItemProps,
  styled,
} from '@mui/material';
import { POPPINS } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IIconButtonProps extends IconButtonProps {
  isdimmed?: 'true';
}

interface IActionMenuItemProps extends MenuItemProps {
  islogout?: 'true';
}

export const CustomIconButton = styled(IconButton)<IIconButtonProps>(
  ({ theme, isdimmed }) => ({
    color: theme.palette.primary.main,
    width: '57px',
    height: '57px',
    padding: '0px',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '50%',
    backgroundColor: theme.palette.background.paper,

    '&::before': {
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)', // Dim color overlay
      transition: 'opacity 0.3s ease',
      pointerEvents: 'none', // Make sure the overlay doesn't block interactions
      opacity: isdimmed ? 1 : 0,
    },

    '&:hover::before': {
      opacity: 1, // Show the dim effect on hover
    },
  })
);

export const ActionMenuItem = styled(MenuItem)<IActionMenuItemProps>(
  ({ islogout, theme }) => ({
    color: islogout ? theme.palette.error.main : theme.palette.primary.dark,
    fontSize: '16px',
    fontFamily: POPPINS,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '-2.2%',
    gap: '10px',
    justifyContent: 'center',
    '&:hover': {
      background: 'transparent',
    },
    '& +.MuiDivider-root': {
      margin: 0,
    },
  })
);

export const ActionsDivider = styled(Divider)(() => ({
  display: 'block',
  width: '100%',
  height: '1px',
  borderColor: COLORS.lightGray,
}));
