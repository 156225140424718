import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainer } from 'views';
import {
  SubTitle,
  Title,
  WrapperServicesConfig,
} from './ServicesConfig.styled';
import { Box } from '@mui/material';
import { ElementBox } from 'ui';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getTools } from 'reduxStore/createProfile/createProfileSelectors';
import { retrieveTools } from 'reduxStore/createProfile/createProfileOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';

const ServicesConfig = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tools = useAppSelector(getTools);

  useEffect(() => {
    dispatch(retrieveAccountInfo(null));
    dispatch(retrieveTools(null));

    const handleModalClose = () => {
      window.location.reload();
    };

    window.addEventListener('modalClose', handleModalClose);

    return () => {
      window.removeEventListener('modalClose', handleModalClose);
    };
  }, [dispatch]);

  const connectedTools = tools.filter(
    tool =>
      (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
      (tool.clients_count !== null && tool.clients_count !== 0)
  );

  const AddIntegration = () => {
    dispatch(
      setModalProps({
        radius: '18px',
      })
    );
    dispatch(openModal());
    dispatch(setContent('AddIntegrationModal'));
  };

  const DeleteIntegration = () => {
    dispatch(
      setModalProps({
        radius: '18px',
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteIntegrationModal'));
  };

  const handleSubmit = () => {
    navigate(ROUTES.DASHBOARD, { state: { from: 'createMainOrganization' } });
  };

  const handleBack = () => {
    navigate(ROUTES.CLIENTS_CONFIG);
  };

  return (
    <WrapperServicesConfig>
      <Box display="flex" flexDirection="column" gap="35px">
        <Box display="flex" flexDirection="column" gap="5px">
          <Title>Import Services</Title>
          <SubTitle>
            Add to your dashboard. Explore the services below.
          </SubTitle>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          padding="0px 2.5%"
        >
          <ElementBox
            width="100%"
            height="44px"
            title="Import Service"
            functionality="Add"
            onClickFunctionality={AddIntegration}
          />
          {Array.isArray(connectedTools) &&
            connectedTools.map((connectedTool, index) => (
              <ElementBox
                key={index}
                width="100%"
                height="44px"
                title={`${connectedTool.name} - ${connectedTool.clients_count} connected ${connectedTool.clients_count === 1 ? 'client' : 'clients'}`}
                functionality="Remove"
                onClickFunctionality={DeleteIntegration}
              />
            ))}
        </Box>
      </Box>

      <ButtonsContainer
        titleButton="Finish"
        onClickBack={handleBack}
        onClick={handleSubmit}
      />
    </WrapperServicesConfig>
  );
};

export default ServicesConfig;
