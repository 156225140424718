import React from 'react';
import { StyledFlatButton } from './FlatButton.styled';
import AddIcon from '@mui/icons-material/Add';
import { FormikValues, useFormikContext } from 'formik';

interface IFlatButtonProps {
  variant?: 'contained' | 'outlined';
  type?: 'button' | 'submit' | 'reset';
  isIcon?: 'true';
  isAPI?: 'true';
  borderradius?: string;
  width?: string;
  height?: string;
  isDisabled?: boolean;
  onClick?: (values?: any) => void;
  children: React.ReactNode | string;
}

const FlatButton = ({
  variant,
  type,
  isIcon,
  isAPI,
  borderradius,
  width,
  height,
  isDisabled,
  onClick,
  children,
}: IFlatButtonProps) => {
  const formikContext = useFormikContext<FormikValues>();
  const onClickHandler = () => {
    if (isAPI && formikContext) {
      onClick?.(formikContext.values);
    } else {
      onClick?.();
    }
  };
  return (
    <StyledFlatButton
      variant={variant}
      type={type}
      isicon={isIcon}
      disabled={isDisabled}
      borderradius={borderradius}
      width={width}
      height={height}
      onClick={onClickHandler}
      endIcon={isIcon && <AddIcon fontSize="large" />}
    >
      {children}
    </StyledFlatButton>
  );
};

export default FlatButton;
