import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface StyledButtonProps extends ButtonProps {
  width?: string;
  height?: string;
  borderradius?: string;
  bordersize?: string;
  usefirstcolor?: 'true' | 'false';
  firsttextandbordercolor?: string;
  firstbackgroundcolor?: string;
  secondtextandbordercolor?: string;
  secondbackgroundcolor?: string;
  fontSize?: string;
}

export const WrapperColorChangingButton = styled(Button)<StyledButtonProps>(
  ({
    theme,
    width,
    height,
    borderradius,
    bordersize,
    usefirstcolor,
    firsttextandbordercolor,
    firstbackgroundcolor,
    secondtextandbordercolor,
    secondbackgroundcolor,
    fontSize,
  }) => ({
    display: 'flex',
    width: width ? width : 'fit-content',
    height: height ? height : 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2px',
    padding: '7px 36px',

    borderRadius: borderradius ? borderradius : '0px',
    border: bordersize ? `${bordersize} solid` : 'none',
    borderColor:
      bordersize && usefirstcolor === 'true'
        ? firsttextandbordercolor
        : bordersize
          ? secondtextandbordercolor
          : '',
    background:
      usefirstcolor === 'true' && firstbackgroundcolor
        ? firstbackgroundcolor
        : usefirstcolor === 'false' && secondbackgroundcolor
          ? secondbackgroundcolor
          : COLORS.white,
    color:
      usefirstcolor === 'true' && firsttextandbordercolor
        ? firsttextandbordercolor
        : usefirstcolor === 'false' && secondtextandbordercolor
          ? secondtextandbordercolor
          : theme.palette.primary.dark,

    fontFamily: INTER,
    fontSize: fontSize ? fontSize : '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0%',

    '&:hover': {
      background:
        usefirstcolor === 'true' && firstbackgroundcolor
          ? firstbackgroundcolor
          : usefirstcolor === 'false' && secondbackgroundcolor
            ? secondbackgroundcolor
            : COLORS.white,
    },
  })
);
