import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/toolHuntress/toolHuntressOperations';
import {
  getHuntressGeneralStatistics,
  getHuntressSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import { WrapperITDR } from './HuntressITDRPage.styled';
import { theme } from 'theme';
import { COLORS } from 'theme/colors.const';
import { DataBox } from 'ui';

const HuntressITDRPage = () => {
  const dispatch = useAppDispatch();
  const generalStatistics = useAppSelector(getHuntressGeneralStatistics);
  const selectedOrgId = useAppSelector(getHuntressSelectedOrgId);

  const activeIncidentsData = [
    {
      title: 'Critical',
      amount: generalStatistics?.incidents.sent.crit_count || 0,
      color: theme.palette.error.main,
    },
    {
      title: 'High',
      amount: generalStatistics?.incidents.sent.high_count || 0,
      color: COLORS.yellowDark,
    },
    {
      title: 'Low',
      amount: generalStatistics?.incidents.sent.low_count || 0,
      color: COLORS.greenDark,
    },
  ];

  useEffect(() => {
    dispatch(retrieveHuntressGeneralStatistics(selectedOrgId));
  }, [dispatch, selectedOrgId]);

  return (
    <WrapperITDR>
      <DataBox
        title="Active Incidents"
        data={activeIncidentsData}
        width="45%"
        height="28vh"
        needsGraph="true"
      />
    </WrapperITDR>
  );
};

export default HuntressITDRPage;
