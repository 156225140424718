import { Box, BoxProps, styled } from '@mui/material';

export const LogoLayoutWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100vw',
  minHeight: '100vh',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '53px 88px 50px 88px',
}));
