import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Title,
  WrapperTeamMember,
  MenuItemText,
  MenuItemBox,
  Error,
  WrapperAssignedClients,
  SubTitle,
} from './AddOrUpdateTeamMember.styled';
import { AssignmentConfig, FlatButton, Input, SectionUploadSmall } from 'ui';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getClients,
  getCreateProfileError,
  getMSPRoles,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect, useState } from 'react';
import {
  createMSPContact,
  retrieveClients,
  retrieveMSPRoles,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getMainOrganizationID,
  getTeamMemberToUpdate,
} from 'reduxStore/organization/organizationSelectors';
import { ICreateContactBody } from 'types/api.type';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { getBase64 } from 'utils';
import { BYTES_IN_2MB } from 'constants/validation';

const validationSchema = Yup.object({
  first_name: Yup.string()
    .min(2, 'Minimum 2 symbols are required')
    .required('First name is required'),
  last_name: Yup.string()
    .min(2, 'Minimum 2 symbols are required')
    .required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  role_id: Yup.string()
    .required('Role is required')
    .test('not-select', 'Role is required', value => value !== '0'),
});
const AddOrUpdateTeamMember = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const mspRoles = useAppSelector(getMSPRoles);
  const organizationId = useAppSelector(getMainOrganizationID);
  const teamMemberToUpdate = useAppSelector(getTeamMemberToUpdate);
  const error = useAppSelector(getCreateProfileError);
  const clients = useAppSelector(getClients);

  const [base64Image, setBase64Image] = useState(
    teamMemberToUpdate?.icon || ''
  );
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  useEffect(() => {
    dispatch(retrieveMSPRoles(null));
    dispatch(retrieveClients(null));
  }, [dispatch]);

  const [isUpdate] = useState(Boolean(teamMemberToUpdate?.email));

  const initialValues: ICreateContactBody = {
    org_id: teamMemberToUpdate?.org_id ?? organizationId,
    first_name: teamMemberToUpdate?.first_name ?? '',
    last_name: teamMemberToUpdate?.last_name ?? '',
    email: teamMemberToUpdate?.email ?? '',
    phone: teamMemberToUpdate?.phone ?? null,
    role_id: teamMemberToUpdate?.role_id ?? 0,
    icon: base64Image,
  };

  const handleSubmit = async (value: ICreateContactBody) => {
    const body: ICreateContactBody = {
      org_id: value.org_id,
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      phone: value.phone,
      role_id: value.role_id,
      icon: teamMemberToUpdate?.icon === base64Image ? null : base64Image,
    };
    try {
      await dispatch(createMSPContact(body)).unwrap();
      navigate(ROUTES.TEAM_MEMBERS);
      if (isUpdate) {
        dispatch(
          setModalProps({
            radius: '18px',
            title: value.email,
            additionalText: 'update',
          })
        );
      } else {
        dispatch(setModalProps({ radius: '18px', title: value.email }));
      }

      dispatch(openModal());
      dispatch(setContent('AddOrUpdateTeamMemberConfirm'));
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperTeamMember>
      {isUpdate ? (
        <Title>Update Team Member</Title>
      ) : (
        <Title>Add New Team Member</Title>
      )}
      {!!error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container flexDirection="column">
              <Grid container spacing={2} flexDirection="row">
                <Grid item xs={6}>
                  <Grid container spacing={2} flexDirection="column">
                    <SubTitle>Team Member Information</SubTitle>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="first_name"
                        label="First Name"
                        placeholder="Enter First Name"
                        helperText={<ErrorMessage name="first_name" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="last_name"
                        label="Last Name"
                        placeholder="Enter Last Name"
                        helperText={<ErrorMessage name="last_name" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="email"
                        label="Email"
                        placeholder="Enter Email"
                        helperText={<ErrorMessage name="email" />}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <MuiPhoneNumber
                          defaultCountry={'us'}
                          name="phone"
                          value={values.phone}
                          label="Phone Number"
                          variant="outlined"
                          placeholder="Enter Phone Number"
                          onChange={value => setFieldValue('phone', value)}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              height: '59px',
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                      <Field
                        name="role_id"
                        as={TextField}
                        id="role_id"
                        label="Choose Role"
                        variant="outlined"
                        select
                        fullWidth
                        error={touched.role_id && Boolean(errors.role_id)}
                        helperText={touched.role_id && errors.role_id}
                        InputLabelProps={{ htmlFor: 'role_id', shrink: true }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                            height: '59px',
                          },
                        }}
                      >
                        <MenuItem value={0} style={{ display: 'none' }}>
                          <MenuItemText>Select Role</MenuItemText>
                        </MenuItem>
                        {Array.isArray(mspRoles) &&
                          mspRoles.map((role, index) => (
                            <MenuItem
                              key={index}
                              value={role.id}
                              style={MenuItemBox}
                            >
                              <MenuItemText>{role.name}</MenuItemText>
                            </MenuItem>
                          ))}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <SectionUploadSmall
                        handleUpload={handleUpload}
                        onDelete={handleDeleteImage}
                        imageUrl={base64Image}
                        error={imageError}
                        title="Upload Icon"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing={2}>
                    <SubTitle>Assignments</SubTitle>
                    <Grid item xs={12}>
                      <WrapperAssignedClients>
                        {Array.isArray(clients) &&
                          clients.map((client, index) => (
                            <AssignmentConfig
                              key={index}
                              title={client.name}
                              orgId={client.id}
                              contactEmail={values.email}
                              height="59px"
                            />
                          ))}
                      </WrapperAssignedClients>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} marginTop="50px">
                <FlatButton
                  width="250px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  {isUpdate ? <>Update Team Member</> : <>Add Team Member</>}
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperTeamMember>
  );
};

export default AddOrUpdateTeamMember;
