import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  MenuItem,
  MenuItemProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { POPPINS } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const OrganizationElementWrapper = styled(Box)<BoxProps>(() => ({
  background: 'transparent',
  width: '100%',
  height: '37px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '8px 10px',
  borderRadius: '32px',
  border: `1px solid ${COLORS.superBlue}`,
  cursor: 'pointer',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: POPPINS,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const CustomIconButton = styled(IconButton)<IconButtonProps>(() => ({
  width: '14px',
  height: '14px',
  padding: '0px',
  border: 'none',
  borderRadius: '50%',
  backgroundColor: 'transparent',
}));

export const ActionMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '16px',
  fontFamily: POPPINS,
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
  gap: '10px',
  justifyContent: 'center',
  '&:hover': {
    background: 'transparent',
  },
  '& +.MuiDivider-root': {
    margin: 0,
  },
}));
