import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface IFlatButtonProps extends ButtonProps {
  variant?: 'contained' | 'outlined';
  isicon?: 'true';
  borderradius?: string;
  width?: string;
  height?: string;
}

export const StyledFlatButton = styled(Button)<IFlatButtonProps>(
  ({ theme, variant, isicon, borderradius, width, height }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: width ? width : isicon ? '123px' : '164px',
    height: isicon ? '44px' : height ? height : '43px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: isicon ? '24px' : '21px',
    letterSpacing: isicon ? '-0.352px' : '-0.308px',
    borderRadius: borderradius ? borderradius : '4px',
    border:
      variant === 'outlined'
        ? `2px solid ${theme.palette.primary.main}`
        : 'none',
    color:
      variant === 'contained'
        ? theme.palette.common.white
        : theme.palette.primary.main,
    background:
      variant === 'contained'
        ? theme.palette.primary.main
        : theme.palette.common.white,
    '&:hover': {
      border:
        variant === 'outlined'
          ? `2px solid ${theme.palette.primary.main}`
          : 'none',
      color:
        variant === 'contained'
          ? theme.palette.common.white
          : theme.palette.primary.main,
      background:
        variant === 'contained'
          ? theme.palette.primary.main
          : theme.palette.common.white,
    },
  })
);
