import React from 'react';
import { WrapperColorChangingButton } from './ColorChangingButton.styled';

interface IColorChangingButtonProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  borderSize?: string;
  useFirstColor?: 'true' | 'false';
  firstTextAndBorderColor?: string;
  firstBackgroundColor?: string;
  secondTextAndBorderColor?: string;
  secondBackgroundColor?: string;
  fontSize?: string;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode | string;
  onClick?: (values?: any) => void;
}

const ColorChangingButton = ({
  width,
  height,
  borderRadius,
  borderSize,
  useFirstColor,
  firstTextAndBorderColor,
  firstBackgroundColor,
  secondTextAndBorderColor,
  secondBackgroundColor,
  fontSize,
  type,
  children,
  onClick,
}: IColorChangingButtonProps) => {
  return (
    <WrapperColorChangingButton
      width={width}
      height={height}
      borderradius={borderRadius}
      bordersize={borderSize}
      usefirstcolor={useFirstColor}
      firstbackgroundcolor={firstBackgroundColor}
      firsttextandbordercolor={firstTextAndBorderColor}
      secondbackgroundcolor={secondBackgroundColor}
      secondtextandbordercolor={secondTextAndBorderColor}
      fontSize={fontSize}
      type={type}
      onClick={onClick}
    >
      {children}
    </WrapperColorChangingButton>
  );
};

export default ColorChangingButton;
