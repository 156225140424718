import { Button, ButtonProps, styled } from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IPositiveNegativeNeutralButtonProps extends ButtonProps {
  width?: string;
  height?: string;
  state?: 'Positive' | 'Negative';
  borderradius?: string;
}

export const CustomPositiveNegativeNeutralButton = styled(
  Button
)<IPositiveNegativeNeutralButtonProps>(
  ({ theme, width, height, state, borderradius }) => ({
    width: width ? width : 'fit-content',
    height: height ? height : 'fit-content',
    padding: '7px 36px',
    color: state ? theme.palette.common.white : COLORS.grayNeutral,
    fontFamily: INTER,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0%',
    borderRadius: borderradius ? borderradius : '8px',
    border: '1px solid',
    borderColor:
      state === 'Negative'
        ? theme.palette.error.main
        : state === 'Positive'
          ? theme.palette.extra.main
          : '',
    background:
      state === 'Negative'
        ? theme.palette.error.main
        : state === 'Positive'
          ? theme.palette.extra.main
          : COLORS.lightGray,
    '&:hover': {
      borderColor:
        state === 'Negative'
          ? theme.palette.error.main
          : state === 'Positive'
            ? theme.palette.extra.main
            : '',
      background:
        state === 'Negative'
          ? theme.palette.error.main
          : state === 'Positive'
            ? theme.palette.extra.main
            : COLORS.lightGray,
      color: state ? theme.palette.common.white : COLORS.grayNeutral,
    },
  })
);
