import { BasicLinks, FlatButton, Logo, ProviderButton } from 'ui';
import { Box, useMediaQuery } from '@mui/material';
import mainImg from '../../assets/images/mainImg.png';
import {
  ContainerSignIn,
  ContentBox,
  ErrorText,
  BackgroundBox,
  Title,
  SubTitle,
} from './SignOn.styled';
import { generateAuthUrl, getPopupWindowParams } from '../../utils';
import { useAppDispatch, useAppSelector, useAuthListener } from 'hooks';
import { getAuthError } from 'reduxStore/auth/authSelectors';
import { resetAuthError } from 'reduxStore/auth/authSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const SignOn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hideImage = useMediaQuery('(max-width:960px)');

  const authError = useAppSelector(getAuthError);

  const [isNew, setIsNew] = useState<boolean>(true);

  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [code, resetCode] = useAuthListener(popupWindow);
  const [provider, setProvider] = useState<'okta' | 'google' | 'microsoft'>(
    'okta'
  );

  useEffect(() => {
    if (!code) return;

    const savedCode = code;
    resetCode();
    navigate(ROUTES.PROCESS_AUTH, {
      state: { code: savedCode, provider: provider },
    });
  }, [code, resetCode, navigate, provider]);

  const handleSignIn = (provider: 'okta' | 'google' | 'microsoft') => {
    setProvider(provider);
    dispatch(resetAuthError());
    const authUrl = generateAuthUrl(provider);

    const authWindow = window.open(
      authUrl,
      'authWindow',
      getPopupWindowParams(500, 700)
    );
    setPopupWindow(authWindow);
  };

  return (
    <ContainerSignIn>
      <ContentBox isimageremoved={hideImage ? 'true' : undefined}>
        <Logo />
        <Box gap="8px">
          <Title>{isNew === true ? 'Get Started' : 'Welcome Back'}</Title>
          <SubTitle>Start Simple, Manage Smarter with Kylada</SubTitle>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="10px"
        >
          <ProviderButton
            onClick={() => handleSignIn('google')}
            width="100%"
            height="59px"
            variant="google"
          >
            Continue with Google
          </ProviderButton>
          <ProviderButton width="100%" height="59px" variant="microsoft">
            Continue with Microsoft
          </ProviderButton>
          <ProviderButton
            width="100%"
            height="59px"
            variant="google_enterprise"
          >
            Continue with Google Enterprise
          </ProviderButton>
          <ProviderButton
            width="100%"
            height="59px"
            variant="microsoft_enterprise"
          >
            Continue with Microsoft Enterprise
          </ProviderButton>
          <FlatButton
            variant="contained"
            onClick={() => handleSignIn('okta')}
            width="100%"
          >
            {isNew === true ? 'Sign In Okta' : 'Log In Okta'}
          </FlatButton>
        </Box>

        <Box display="flex" justifyContent="center">
          <BasicLinks
            text={
              isNew === true ? 'Already have an account?' : 'New to Kylada?'
            }
            nameLink={isNew === true ? 'login account' : 'Sign up now'}
            onClick={() => {
              setIsNew(prevState => !prevState);
            }}
          />
        </Box>
        {authError && (
          <Box marginTop="50px">
            <ErrorText>{authError.title}</ErrorText>
            <ErrorText>{authError.text}</ErrorText>
          </Box>
        )}
      </ContentBox>
      {!hideImage && <BackgroundBox image={mainImg} />}
    </ContainerSignIn>
  );
};

export default SignOn;
