import { useEffect, useState } from 'react';
import {
  WrapperWhite,
  Title,
  HeaderOfView,
  Error,
  MenuItemBox,
  MenuItemText,
} from './BusinessSettingsView.styled';
import { ColorChangingButton, Input, SectionUploadLarge } from 'ui';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IOrgProfileFormValues } from 'types';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';
import createValidationSchema from './validationSchema';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { theme } from 'theme';
import {
  getMainOrganizationID,
  getOrganizationError,
  getSelectedOrganization,
} from 'reduxStore/organization/organizationSelectors';
import { IUpdateOrgBody } from 'types/api.type';
import countryList from 'react-select-country-list';
import {
  retrieveOrganization,
  updateOrganization,
} from 'reduxStore/organization/organizationOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

const BusinessSettingsView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const mainOrgInfo = useAppSelector(getSelectedOrganization);
  const countryOptions = countryList().getData();

  const [isEditing, setIsEditing] = useState(false);
  const error = useAppSelector(getOrganizationError);

  useEffect(() => {
    dispatch(retrieveOrganization(mainOrgId));
  }, [dispatch, mainOrgId]);

  const handleEditChange = () => {
    setIsEditing(prevState => !prevState);
  };
  const [base64Image, setBase64Image] = useState(mainOrgInfo?.icon || '');
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  const handleSubmit = async (value: IOrgProfileFormValues) => {
    const body: IUpdateOrgBody = {
      id: mainOrgInfo?.id ?? 0,
      is_msp: mainOrgInfo?.is_msp,
      name: value.name,
      address: {
        state: value.state,
        street: value.street,
        street2: value.street2,
        city: value.city,
        zip_code: value.zipCode,
        country_code: value.country_code,
      },
      margin: mainOrgInfo?.margin,
      icon: base64Image === mainOrgInfo?.icon ? null : base64Image,
    };
    try {
      await dispatch(updateOrganization(body)).unwrap();
      setIsEditing(false);
      dispatch(setModalProps({ radius: '22px' }));
      dispatch(openModal());
      dispatch(setContent('BusinessSettingsChangeConfirm'));
    } catch (error) {
      return;
    }
  };
  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const setInitialValues = (): IOrgProfileFormValues => {
    const values = {
      admins: mainOrgInfo?.admins ?? [],
      name: mainOrgInfo?.name ?? '',
      street: mainOrgInfo?.address.street ?? '',
      street2: mainOrgInfo?.address.street2 ?? '',
      city: mainOrgInfo?.address.city ?? '',
      state: mainOrgInfo?.address.state ?? '',
      zipCode: mainOrgInfo?.address.zip_code ?? '',
      country_code: mainOrgInfo?.address.country_code ?? '',
    };
    return values;
  };

  return (
    <WrapperWhite>
      <HeaderOfView>
        <Title>Business Settings</Title>
        <ColorChangingButton
          onClick={handleEditChange}
          useFirstColor={isEditing ? 'false' : 'true'}
          firstTextAndBorderColor={theme.palette.primary.main}
          secondTextAndBorderColor={theme.palette.error.main}
          borderRadius="23px"
          borderSize="1px"
          fontSize="19px"
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </ColorChangingButton>
      </HeaderOfView>
      {error && <Error>{error}</Error>}
      <Formik
        initialValues={setInitialValues()}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Box display="flex" flexDirection="row" gap="15vw" marginTop="46px">
              <Box>
                <Grid container spacing="38px" width="100%" maxWidth="630px">
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="name"
                      label="Company Name"
                      helperText={<ErrorMessage name="name" />}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="street"
                      label="Address 1"
                      helperText={<ErrorMessage name="street" />}
                      disabled={!isEditing}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="street2"
                      label="Address 2"
                      disabled={!isEditing}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel shrink>Select Country</InputLabel>
                      <Field name="country_code">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            select
                            label="Select Country"
                            variant="outlined"
                            fullWidth
                            error={
                              touched.country_code &&
                              Boolean(errors.country_code)
                            }
                            helperText={
                              touched.country_code && errors.country_code
                            }
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                              },
                            }}
                            disabled={!isEditing}
                          >
                            {Array.isArray(countryOptions) &&
                              countryOptions.map(country => (
                                <MenuItem
                                  key={country.value}
                                  value={country.value}
                                  style={MenuItemBox}
                                >
                                  <MenuItemText>{country.label}</MenuItemText>
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="city"
                      label="City"
                      helperText={<ErrorMessage name="city" />}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="state"
                      label="State"
                      helperText={<ErrorMessage name="state" />}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="zipCode"
                      label="Zip code"
                      helperText={<ErrorMessage name="zipCode" />}
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>
              </Box>
              <SectionUploadLarge
                handleUpload={handleUpload}
                onDelete={handleDeleteImage}
                imageUrl={base64Image}
                error={imageError}
                notAvailableToEdit={!isEditing ? 'true' : undefined}
                title="Company Logo"
              />
            </Box>
            <Box
              marginTop="20px"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              width="100%"
            >
              <ColorChangingButton
                width="129px"
                height="59px"
                borderRadius="12px"
                useFirstColor={isEditing ? 'false' : 'true'}
                firstBackgroundColor={theme.palette.secondary.light}
                firstTextAndBorderColor={theme.palette.common.white}
                secondBackgroundColor={theme.palette.primary.main}
                secondTextAndBorderColor={theme.palette.common.white}
                type={isEditing ? 'submit' : 'button'}
                onClick={isEditing ? undefined : handleClick}
              >
                {isEditing ? 'Save' : 'Back'}
              </ColorChangingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </WrapperWhite>
  );
};

export default BusinessSettingsView;
