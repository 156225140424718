import { StandardTextFieldProps, styled, TextField } from '@mui/material';
import { INTER, ISTOK_WEB } from 'constants/fonts';

interface ITextFieldProps extends StandardTextFieldProps {
  height?: string;
  InputLabelProps?: any;
  isblocked?: 'true';
  placeholder?: string;
}

export const StyledBlockedTextField = styled(TextField)<ITextFieldProps>(
  ({ theme, isblocked, height }) => ({
    width: '100%',
    borderRadius: '12px',
    '& .MuiInputBase-root': {
      height: height ? height : '59px',
    },
    '&label': {
      color: theme.palette.text.disabled,
      fontFamily: ISTOK_WEB,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      background: 'transparent',
      paddingLeft: '6px',
      paddingRight: '6px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: isblocked ? '#ffffcc' : 'transparent', // Yellow for blocked, default for disabled
      '& fieldset': {
        borderColor: '#ff69b4', // Pink for blocked, default for disabled
      },
      '&:hover fieldset': {
        borderColor: isblocked ? '#ff69b4' : theme?.palette.action.disabled, // No change on hover
      },
    },
    '& .MuiInputBase-input': {
      paddingLeft: '20px',
      color: theme.palette.primary.dark,
      fontFamily: INTER,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
    },
  })
);
