export const ROUTES = {
  DASHBOARD: '/',
  SIGNIN: '/login',

  //right now does not used
  REDIRECT: 'redirect',
  PROCESS_AUTH: '/process-auth',

  //TODO: MIGHT NEED TO DELETE IT
  SET_UP: '/set_up',

  //steps in account creation
  ORGANIZATION_PROFILE: '/organization_profile',
  CLIENTS_CONFIG: '/clients_config',
  SERVICES_CONFIG: '/services_config',
  TEAM_MEMBERS_CONFIG: '/team_members_config',

  //TEAM MEMBERS PAGES
  TEAM_MEMBERS: '/team_members',
  TEAM_MEMBERS_LIST: '/team_members/team_members_list',
  ADD_OR_UPDATE_TEAM_MEMBER: '/team_members/add_or_update_team_member',
  DELETE_TEAM_MEMBER: '/team_members/delete_team_member',

  //CLIENTS PAGES
  CLIENTS: '/clients',
  CLIENTS_LIST: '/clients/clients_list',
  ADD_CLIENT: '/clients/add_client',
  UPDATE_CLIENT: '/clients/update_client',
  DELETE_CLIENT: '/clients/delete_client',

  //SERVICES PAGES
  SERVICES: '/services',
  ADD_SERVICE: '/services/add_service',
  DELETE_INTEGRATION: '/services/delete_integration',

  SERVICE_PAGE: '/service_page',

  TICKETS: '/tickets',
  AUDIT_LOGS: '/audit_logs',

  // --- HUNTRESS PAGES ---
  HUNTRESS_DASHBOARD: '/services/huntress_dashboard',
  // --- Huntress Dashboard Children ---
  HUNTRESS_DASHBOARD_AGENTS: '/services/huntress_dashboard/agents',
  HUNTRESS_DASHBOARD_INCIDENTS: '/services/huntress_dashboard/incidents',
  HUNTRESS_DASHBOARD_REPORTS: '/services/huntress_dashboard/reports',

  HUNTRESS_EDR: '/services/huntress_edr',
  HUNTRESS_ITDR: '/services/huntress_itdr',

  // --- Settings Pages ---
  BUSINESS_SETTINGS: '/business_settings',
  ACCOUNT_SETTINGS: '/account_settings',
};

export const CREATE_ORG_ROUTES = [
  ROUTES.ORGANIZATION_PROFILE,
  ROUTES.CLIENTS_CONFIG,
  ROUTES.SERVICES_CONFIG,
  ROUTES.TEAM_MEMBERS_CONFIG,
];
