import {
  ClickAwayListener,
  InputAdornment,
  InputProps,
  StandardTextFieldProps,
  Tooltip,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { StyledBlockedTextField } from './BlockedInput.styled';
import LockIcon from '@mui/icons-material/Lock';
import { COLORS } from 'theme/colors.const';

interface IBlockedInputProps extends StandardTextFieldProps {
  type?: 'text' | 'email' | 'tel' | 'number';
  required?: boolean;
  label?: string;
  placeholder?: string;
  height?: string;
  background?: 'gray' | 'white';
  field?: any;
  form?: any;
  helperText?: ReactElement;
  InputProps?: InputProps;
  isBlocked?: 'true';
  blockedText?: string;
}

//TODO: might need to make it better
const BlockedInput = ({
  field,
  form,
  type,
  isBlocked,
  blockedText,
  ...props
}: IBlockedInputProps) => {
  const { ...fieldProps } = field;
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  return (
    <StyledBlockedTextField
      {...props}
      {...fieldProps}
      isblocked={isBlocked}
      disabled // Always disabled
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...props.InputProps,

        endAdornment: isBlocked ? (
          <InputAdornment position="end">
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                open={openTooltip}
                onClose={handleTooltipClose}
                title={blockedText}
                placement="right"
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#F4F4E8', // Tooltip background
                      color: COLORS.lightBlack, // Tooltip text color
                      fontSize: '12px', // Text size
                      fontWeight: 500, // Text weight
                      borderRadius: '24px', // Rounded corners
                      border: `1px solid ${COLORS.grayTooltip}`,
                    },
                  },
                }}
              >
                <LockIcon
                  onClick={handleTooltipOpen}
                  sx={{
                    cursor: 'pointer',
                    color: COLORS.lightBlack,
                  }}
                />
              </Tooltip>
            </ClickAwayListener>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default BlockedInput;
