import { useAppDispatch } from 'hooks';
import { closeModal } from 'reduxStore/modal/modalSlice';
import { MainText, WrapperDeletion } from './DeleteConfirmation.styled';
import { Box } from '@mui/material';
import { FlatButton } from 'ui';
import { ROUTES } from 'routes/routes.const';
import {
  deleteClient,
  deleteContact,
  deleteIntegration,
} from 'reduxStore/createProfile/createProfileOperations';
import { useNavigate } from 'react-router';

interface IDeleteConfirmation {
  deletionType: string | undefined;
  apiValue: any;
  doesNotNeedNavigation?: 'true';
}

const DeleteConfirmation = ({
  deletionType,
  apiValue,
  doesNotNeedNavigation,
}: IDeleteConfirmation) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleAPICall = async () => {
    const newApiValue = { ...apiValue, is_total: true };
    switch (deletionType) {
      case 'client':
        try {
          await dispatch(deleteClient(apiValue)).unwrap();
          if (doesNotNeedNavigation !== 'true') navigate(ROUTES.CLIENTS);
        } catch (error) {
          dispatch(closeModal());
          return;
        }
        break;
      case 'integration':
        try {
          await dispatch(deleteIntegration(newApiValue)).unwrap();
          if (doesNotNeedNavigation !== 'true') navigate(ROUTES.SERVICES);
        } catch (error) {
          dispatch(closeModal());
          return;
        }
        break;
      case 'team member':
        try {
          await dispatch(deleteContact(apiValue)).unwrap();
          if (doesNotNeedNavigation !== 'true') navigate(ROUTES.TEAM_MEMBERS);
        } catch (error) {
          dispatch(closeModal());
          return;
        }
    }
    dispatch(closeModal());
  };

  const handleCancelDelete = () => {
    dispatch(closeModal());
  };

  return (
    <WrapperDeletion>
      <Box width="500px" height="96px">
        <MainText>
          {deletionType === 'integration'
            ? 'Disconnecting MSP from service will automatically disconnect all clients too. Proceed?'
            : `Are you sure you want to delete this ${deletionType}?`}
        </MainText>
      </Box>
      <Box
        width="500px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <FlatButton
          onClick={handleAPICall}
          variant="contained"
          width="134px"
          height="50px"
          isAPI="true"
        >
          YES
        </FlatButton>
        <FlatButton
          onClick={handleCancelDelete}
          variant="outlined"
          width="134px"
          height="50px"
        >
          NO
        </FlatButton>
      </Box>
    </WrapperDeletion>
  );
};

export default DeleteConfirmation;
