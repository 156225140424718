import { Box } from '@mui/material';
import {
  Add,
  Edit,
  Remove,
  Title,
  WrapperElementBox,
} from './ElementBox.styled';

interface IElementBoxProps {
  title: string;
  width?: string;
  height?: string;
  functionality?: 'Add' | 'Remove' | 'Edit';
  additionalFunctionality?: 'Add' | 'Remove' | 'Edit';
  onClickFunctionality?: (values?: any) => void;
  onClickAdditionalFunctionality?: (values?: any) => void;
}

const ElementButton = ({
  title,
  width,
  height,
  functionality,
  additionalFunctionality,
  onClickFunctionality,
  onClickAdditionalFunctionality,
}: IElementBoxProps) => {
  return (
    <WrapperElementBox width={width} height={height}>
      <Title>{title}</Title>
      <Box display="flex" flexDirection="row" gap="20px" alignItems="right">
        {additionalFunctionality === 'Add' ? (
          <Add onClick={onClickAdditionalFunctionality} underline="none">
            {additionalFunctionality}
          </Add>
        ) : additionalFunctionality === 'Remove' ? (
          <Remove onClick={onClickAdditionalFunctionality} underline="none">
            {additionalFunctionality}
          </Remove>
        ) : (
          <Edit onClick={onClickAdditionalFunctionality} underline="none">
            {additionalFunctionality}
          </Edit>
        )}
        {functionality === 'Add' ? (
          <Add onClick={onClickFunctionality} underline="none">
            {functionality}
          </Add>
        ) : functionality === 'Remove' ? (
          <Remove onClick={onClickFunctionality} underline="none">
            {functionality}
          </Remove>
        ) : (
          <Edit onClick={onClickFunctionality} underline="none">
            {functionality}
          </Edit>
        )}
      </Box>
    </WrapperElementBox>
  );
};

export default ElementButton;
