import { ButtonsWrapper } from './ButtonsContainer.styled';
import { FlatButton } from 'ui/buttons';
import { Box } from '@mui/material';

interface IButtonsContainer {
  titleButton: string;
  onClick?: () => void;
  onClickBack?: () => void;
  type?: 'submit' | 'button';
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  position?: string;
}

const ButtonsContainer = ({
  titleButton,
  top,
  right,
  left,
  bottom,
  position,
  type,
  onClick,
  onClickBack,
}: IButtonsContainer) => {
  const handleClick = () => {
    onClick && onClick();
  };

  const handleClickBack = () => {
    onClickBack && onClickBack();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={position ? position : 'flex-end'}
      width="100%"
    >
      <ButtonsWrapper top={top} right={right} bottom={bottom} left={left}>
        <FlatButton onClick={handleClickBack} height="59px" borderradius="12px">
          Back
        </FlatButton>
        <FlatButton
          variant="contained"
          onClick={handleClick}
          height="59px"
          borderradius="12px"
          type={type ?? 'button'}
        >
          {titleButton}
        </FlatButton>
      </ButtonsWrapper>
    </Box>
  );
};

export default ButtonsContainer;
