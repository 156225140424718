import React from 'react';
import { ButtonProps } from '@mui/material';
import { CustomPositiveNegativeNeutralButton } from './PositiveNegativeNeutralButton.styled';

interface IPositiveNegativeNeutralButtonProps extends ButtonProps {
  width?: string;
  height?: string;
  state?: 'Positive' | 'Negative';
  borderradius?: string;
  onClick?: (values?: any) => void;
  children: React.ReactNode | string;
}

const PositiveNegativeNeutralButton = ({
  width,
  height,
  state,
  borderradius,
  onClick,
  children,
}: IPositiveNegativeNeutralButtonProps) => {
  return (
    <CustomPositiveNegativeNeutralButton
      borderradius={borderradius}
      width={width}
      height={height}
      onClick={onClick}
      state={state}
    >
      {children}
    </CustomPositiveNegativeNeutralButton>
  );
};

export default PositiveNegativeNeutralButton;
