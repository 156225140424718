import { Box, BoxProps, styled, Typography } from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperBusinessSettingsChangeConfirm = styled(Box)<BoxProps>(
  ({ theme }) => ({
    height: '277px',
    width: '541px',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    alignItems: 'center',
    padding: '36px 39px 28px',
    justifyContent: 'space-between',
  })
);

export const Title = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '133.1%',
  letterSpacing: '0%',
  textAlign: 'center',
}));

export const IconAndTitle = styled(Box)<BoxProps>(() => ({
  width: '421px',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '7px',
}));

export const Buttons = styled(Box)<BoxProps>(() => ({
  width: '100%',
  height: '59px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
