import { useAppDispatch, useAppSelector } from 'hooks';
import {
  Title,
  WrapperUpdateClient,
  Error,
  MenuItemBox,
  MenuItemText,
} from './UpdateClient.styled';
import { useNavigate } from 'react-router';
import countryList from 'react-select-country-list';
import {
  getOrganizationError,
  getSelectedOrganization,
} from 'reduxStore/organization/organizationSelectors';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';
import { useState } from 'react';
import { IOrgProfileFormValues } from 'types';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import createValidationSchema from './validationSchema';
import { FlatButton, Input, SectionUploadSmall } from 'ui';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { IUpdateOrgBody } from 'types/api.type';
import { updateOrganization } from 'reduxStore/organization/organizationOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { ROUTES } from 'routes/routes.const';

const UpdateClient = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clientInfo = useAppSelector(getSelectedOrganization);
  const countryOptions = countryList().getData();
  const error = useAppSelector(getOrganizationError);

  const [base64Image, setBase64Image] = useState(clientInfo?.icon || '');
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  const handleSubmit = async (value: IOrgProfileFormValues) => {
    const body: IUpdateOrgBody = {
      id: clientInfo?.id ?? 0,
      is_msp: clientInfo?.is_msp,
      name: value.name,
      address: {
        state: value.state,
        street: value.street,
        street2: value.street2,
        city: value.city,
        zip_code: value.zipCode,
        country_code: value.country_code,
      },
      margin: clientInfo?.margin,
      icon: base64Image === clientInfo?.icon ? null : base64Image,
    };
    try {
      await dispatch(updateOrganization(body)).unwrap();
      navigate(ROUTES.CLIENTS);
      dispatch(
        setModalProps({
          radius: '18px',
          title: value.name,
          additionalText: 'update',
        })
      );
      dispatch(openModal());
      dispatch(setContent('AddOrUpdateClientConfirm'));
    } catch (error) {
      return;
    }
  };

  const initialValues: IOrgProfileFormValues = {
    admins: clientInfo?.admins ?? [],
    name: clientInfo?.name ?? '',
    street: clientInfo?.address.street ?? '',
    street2: clientInfo?.address.street2 ?? '',
    city: clientInfo?.address.city ?? '',
    state: clientInfo?.address.state ?? '',
    zipCode: clientInfo?.address.zip_code ?? '',
    country_code: clientInfo?.address.country_code ?? '',
  };

  return (
    <WrapperUpdateClient>
      {error && <Error>{error}</Error>}
      <Title>Update Client Info</Title>

      <Formik
        initialValues={initialValues}
        validationSchema={createValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container flexDirection="column">
              <Grid container flexDirection="row" spacing="4vw">
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="38px">
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="name"
                        label="Company Name"
                        helperText={<ErrorMessage name="name" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="38px">
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="street"
                            label="Address 1"
                            helperText={<ErrorMessage name="street" />}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="street2"
                            label="Address 2"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="38px">
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel shrink>Select Country</InputLabel>
                            <Field name="country_code">
                              {({ field }: FieldProps) => (
                                <TextField
                                  {...field}
                                  select
                                  label="Select Country"
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    touched.country_code &&
                                    Boolean(errors.country_code)
                                  }
                                  helperText={
                                    touched.country_code && errors.country_code
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      borderRadius: '12px',
                                    },
                                  }}
                                >
                                  {Array.isArray(countryOptions) &&
                                    countryOptions.map(country => (
                                      <MenuItem
                                        key={country.value}
                                        value={country.value}
                                        style={MenuItemBox}
                                      >
                                        <MenuItemText>
                                          {country.label}
                                        </MenuItemText>
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="city"
                            label="City"
                            helperText={<ErrorMessage name="city" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="38px">
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="state"
                            label="State"
                            helperText={<ErrorMessage name="state" />}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={Input}
                            name="zipCode"
                            label="Zip code"
                            helperText={<ErrorMessage name="zipCode" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <SectionUploadSmall
                        handleUpload={handleUpload}
                        onDelete={handleDeleteImage}
                        imageUrl={base64Image}
                        error={imageError}
                        title="Update Company Logo"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/*TODO: Add Assigned Team Members here*/}
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="38px"></Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} marginTop="50px">
                <FlatButton
                  width="250px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Update Client
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperUpdateClient>
  );
};

export default UpdateClient;
