import { StyledLogo } from './Logo.styled';

interface ILogoProps {
  width?: string;
  height?: string;
}

const Logo = ({ width, height }: ILogoProps) => {
  return <StyledLogo width={width} height={height} />;
};

export default Logo;
