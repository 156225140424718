import { styled, Tabs, TabsProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { COLORS } from 'theme/colors.const';

interface ITabsProps extends TabsProps {
  width: string;
  height: string;
}

export const WrapperTabs = styled(Tabs)<ITabsProps>(({ width, height }) => ({
  width,
  maxHeight: height,
  height,
  '& .MuiTabs-flexContainer': {
    width,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '15px',
    background: 'inherit',
  },
  '& .MuiTab-root': {
    gap: '7px',
    justifyContent: 'start',
    '& .MuiTab-iconWrapper': {
      margin: 0,
    },
  },
}));

export const CustomLink = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  color: COLORS.black,
  textTransform: 'capitalize',
  fontSize: '24px',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));
