import { NavigationLogically } from 'ui';
import {
  Container,
  WrapperSidebar,
  NavContainer,
} from './SideBarLogically.styled';

const SideBarLogically = () => {
  return (
    <Container>
      <WrapperSidebar>
        <NavContainer>
          <NavigationLogically />
        </NavContainer>
      </WrapperSidebar>
    </Container>
  );
};

export default SideBarLogically;
