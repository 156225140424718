import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { Formik, Form, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Title,
  SubTitle,
  WrapperAddClientModal,
  MenuItemBox,
  MenuItemText,
  Error,
} from './AddClientModal.styled';
import { FlatButton, Input, SectionUploadSmall } from 'ui';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import {
  IAdminsForClientV2Body,
  IAssignableContactBody,
  ICreateClientBody,
} from 'types/api.type';
import {
  createClient,
  retrieveAssignableContacts,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getAssignableContacts,
  getCreateProfileError,
} from 'reduxStore/createProfile/createProfileSelectors';
import countryList from 'react-select-country-list';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';

interface IClientFormValues {
  name: string;
  street: string;
  street2: string | undefined;
  city: string;
  state: string;
  country_code: string;
  admins: string[];
  clientAdminFirstName: string;
  clientAdminLastName: string;
  clientAdminEmail: string;
  clientAdminPhone: string | null;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Client name is required'),
  clientAdminFirstName: Yup.string().required(
    'Client Admin First Name is required'
  ),
  clientAdminLastName: Yup.string().required(
    'Client Admin Last Name is required'
  ),
  clientAdminEmail: Yup.string()
    .email('Invalid email')
    .required('Client Admin Email is required'),
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State/Region is required'),
  country_code: Yup.string().required('Country is required'),
});

const ReformatContact = (arr: (string | undefined)[]) => {
  const updatedContact = arr.map(value => (value === null ? 'Missing' : value));

  return updatedContact
    .map((value, index) =>
      index >= 0 && index < 2 ? ` ${value}` : ` - ${value}`
    )
    .join(' ');
};

const AddClientModal = () => {
  const dispatch = useAppDispatch();
  const organizationId = useAppSelector(getMainOrganizationID);
  const contacts = useAppSelector(getAssignableContacts);
  const error = useAppSelector(getCreateProfileError);

  const countryOptions = countryList().getData();

  const [base64Image, setBase64Image] = useState('');
  const [imageError, setImageError] = useState<string | null>(null);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };

  useEffect(() => {
    dispatch(retrieveAssignableContacts(null));
  }, [dispatch]);

  const initialValues: IClientFormValues = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    country_code: 'US',
    admins: [],
    clientAdminFirstName: '',
    clientAdminLastName: '',
    clientAdminEmail: '',
    clientAdminPhone: null,
  };

  const handleSubmit = async (value: IClientFormValues) => {
    const selectedContacts = contacts.filter(contact =>
      value.admins.includes(contact.email)
    );
    const reformatedSelectedContacts: IAdminsForClientV2Body[] =
      selectedContacts.map((contact: IAssignableContactBody) => {
        return {
          email: contact.email,
          role_id: contact.role.id,
          first_name: contact.first_name,
          last_name: contact.last_name,
          phone: contact.phone,
        };
      });
    const clientAdmin = {
      email: value.clientAdminEmail,
      first_name: value.clientAdminFirstName,
      last_name: value.clientAdminLastName,
      phone: value.clientAdminPhone,
      role_id: undefined,
    };
    reformatedSelectedContacts.push(clientAdmin);

    const body: ICreateClientBody = {
      is_msp: false,
      msp_id: organizationId,
      name: value.name,
      address: {
        street: value.street,
        street2: value.street2,
        city: value.city,
        state: value.state,
        zip_code: '',
        country_code: value.country_code,
      },
      admins: reformatedSelectedContacts,
      icon: base64Image,
      margin: 0,
    };
    try {
      await dispatch(createClient(body)).unwrap();
      dispatch(setModalProps({ radius: '18px', title: value.name }));
      dispatch(openModal());
      dispatch(setContent('AddClientConfirm'));
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperAddClientModal>
      <Title>Add a Client</Title>
      {error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container flexDirection="column">
              <Grid container flexDirection="row" spacing="4vw">
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="20px">
                    <SubTitle>Client Information</SubTitle>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="name"
                        label="Company Name"
                        placeholder="Enter Company Name"
                        helperText={<ErrorMessage name="name" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="street"
                        label="Address 1"
                        placeholder="Enter Street Name"
                        helperText={<ErrorMessage name="street" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="street2"
                        label="Address 2"
                        placeholder="Enter Street Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container flexDirection="row" spacing="1vw">
                        <Grid item xs={8}>
                          <Field
                            component={Input}
                            name="city"
                            label="City"
                            placeholder="Enter City"
                            helperText={<ErrorMessage name="city" />}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Field
                            component={Input}
                            name="state"
                            label="State"
                            placeholder="Enter State"
                            helperText={<ErrorMessage name="state" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel shrink>Select Country</InputLabel>
                        <Field name="country_code">
                          {({ field }: FieldProps) => (
                            <TextField
                              {...field}
                              select
                              label="Select Country"
                              variant="outlined"
                              fullWidth
                              error={
                                touched.country_code &&
                                Boolean(errors.country_code)
                              }
                              helperText={
                                touched.country_code && errors.country_code
                              }
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '12px',
                                  height: '59px',
                                },
                              }}
                            >
                              {Array.isArray(countryOptions) &&
                                countryOptions.map(country => (
                                  <MenuItem
                                    key={country.value}
                                    value={country.value}
                                    style={MenuItemBox}
                                  >
                                    <MenuItemText>{country.label}</MenuItemText>
                                  </MenuItem>
                                ))}
                            </TextField>
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="admins">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Select Admins"
                            variant="outlined"
                            select
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                              multiple: true,
                              renderValue: (value: unknown) => {
                                const selected = value as string[];
                                return selected.join(', ');
                              },
                            }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                                height: '59px',
                              },
                            }}
                          >
                            {Array.isArray(contacts) &&
                              contacts.map(contact => (
                                <MenuItem
                                  key={contact.email}
                                  value={contact.email}
                                  style={MenuItemBox}
                                >
                                  <MenuItemText>
                                    {ReformatContact([
                                      contact.first_name,
                                      contact.last_name,
                                      contact.email,
                                      contact.role.name,
                                    ])}
                                  </MenuItemText>
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container flexDirection="column" spacing="20px">
                    <SubTitle>Client Contact</SubTitle>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="clientAdminFirstName"
                        label="Client Admin First Name"
                        placeholder="Enter Client Admin First Name"
                        helperText={
                          <ErrorMessage name="clientAdminFirstName" />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="clientAdminLastName"
                        label="Client Admin Last Name"
                        placeholder="Enter Client Admin Last Name"
                        helperText={<ErrorMessage name="clientAdminLastName" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name="clientAdminEmail"
                        label="Client Admin Contact Email"
                        placeholder="Enter Client Admin Contact Email"
                        helperText={<ErrorMessage name="clientAdminEmail" />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPhoneNumber
                        defaultCountry={'us'}
                        name="clientAdminPhone"
                        label="Client Admin Contact Phone"
                        variant="outlined"
                        value={values.clientAdminPhone}
                        onChange={value =>
                          setFieldValue('clientAdminPhone', value)
                        }
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                            height: '59px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SectionUploadSmall
                        handleUpload={handleUpload}
                        onDelete={handleDeleteImage}
                        imageUrl={base64Image}
                        error={imageError}
                        title="Upload New Company Logo"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} marginTop="54px">
                <FlatButton
                  width="150px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Add Client
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperAddClientModal>
  );
};

export default AddClientModal;
