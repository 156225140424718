import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER, ISTOK_WEB } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
  color: theme.palette.text.primary,
}));

export const WrapperWhite = styled(Box)<BoxProps>(() => ({
  background: COLORS.white,
  padding: '49px 76px 32px 76px',
  borderRadius: '18px',
  width: '100%',
  position: 'relative',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const HeaderOfView = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: '400',
  letterSpacing: '-0.352px',
}));
