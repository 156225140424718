import { Stack } from '@mui/material';
import { CustomDivider, HuntressContainer } from './HuntressLayoutPage.styled';
import { Outlet } from 'react-router';
import { TextTabs } from 'ui';
import { ROUTES } from 'routes/routes.const';

const HuntressLayoutPage = () => {
  const navOptions = [
    {
      title: 'Dashboard',
      url: ROUTES.HUNTRESS_DASHBOARD,
    },
    {
      title: 'EDR',
      url: ROUTES.HUNTRESS_EDR,
    },
    {
      title: 'ITDR',
      url: ROUTES.HUNTRESS_ITDR,
    },
  ];

  return (
    <Stack direction="row" width="100%">
      <HuntressContainer>
        <TextTabs
          height="20px"
          width="300px"
          options={navOptions}
          hierarchy="parent"
        ></TextTabs>
        <CustomDivider />
        <Outlet />
      </HuntressContainer>
    </Stack>
  );
};

export default HuntressLayoutPage;
