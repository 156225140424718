import React, { useState } from 'react';
import { IActionsProps } from 'types/selectItem.type';
import { Box, Menu } from '@mui/material';
import {
  ActionMenuItem,
  CustomIconButton,
  ActionsDivider,
} from './ActionsDropdown.styled';

interface IActionsDropdownProps {
  actions: IActionsProps[];
  children: React.JSX.Element;
}

const ActionsDropdown = ({ actions, children }: IActionsDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box position="relative" display="inline-block">
      <CustomIconButton
        onClick={handleClick}
        isdimmed={open ? 'true' : undefined}
      >
        {children}
      </CustomIconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom', // Align the top of the menu with the bottom of the button
          horizontal: 'center', // Center the menu horizontally
        }}
        transformOrigin={{
          vertical: 'top', // Align the bottom of the menu with the top of the button
          horizontal: 'center', // Center the menu horizontally
        }}
      >
        {actions.map((action, index) => [
          <Box>
            <ActionMenuItem
              onClick={action.onClick}
              islogout={
                action.title.toLowerCase() === 'logout' ? 'true' : undefined
              }
              key={index}
            >
              {action.icon} {action.title}
            </ActionMenuItem>
            {index < actions.length - 1 && <ActionsDivider />}
          </Box>,
        ])}
      </Menu>
    </Box>
  );
};

export default ActionsDropdown;
