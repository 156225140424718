import { useAppDispatch, useAppSelector } from 'hooks';
import { Wrapper } from './HuntressIncidents.styled';
import {
  //  getHuntressIncidentRefs,
  getHuntressIncidents,
  getHuntressSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import { useEffect } from 'react';
import {
  retrieveHuntressIncidents,
  retrieveHuntressIncidentsRefs,
} from 'reduxStore/toolHuntress/toolHuntressOperations';
import { IHuntressIncidentsBody } from 'types/huntress.type';
import { TableBasis } from 'ui';
import { HUNTRESS_INCIDENTS_TABLE } from 'constants/tablesHead';

const HuntressIncidents = () => {
  const dispatch = useAppDispatch();
  //NEEDED FOR FILTERS
  //const incidentRefs = useAppSelector(getHuntressIncidentRefs);
  const selectedOrgId = useAppSelector(getHuntressSelectedOrgId);
  const incidents = useAppSelector(getHuntressIncidents);

  const reformatedIncidents = incidents.map(incident => {
    return {
      entity: incident.entity,
      sent_at: incident.sent_at,
      platform: incident.platform.name,
      actions: incident.actions,
      remediations: incident.remediations,
      severity: incident.severity.name,
      status: incident.status.name,
    };
  });

  useEffect(() => {
    const body: IHuntressIncidentsBody = {
      org_id: selectedOrgId,
      indicator_id: null,
      status_id: null,
      platform_id: null,
      severity_id: null,
    };
    dispatch(retrieveHuntressIncidentsRefs(selectedOrgId));
    dispatch(retrieveHuntressIncidents(body));
  }, [dispatch, selectedOrgId]);
  return (
    <Wrapper>
      <TableBasis
        columns={HUNTRESS_INCIDENTS_TABLE}
        tableData={reformatedIncidents}
      ></TableBasis>
    </Wrapper>
  );
};

export default HuntressIncidents;
