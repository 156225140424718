import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainer } from 'views';
import { SubTitle, Title, WrapperClientConfig } from './ClientsConfig.styled';
import { Box } from '@mui/material';
import { ElementBox } from 'ui';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getClients } from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect } from 'react';
import { retrieveClients } from 'reduxStore/createProfile/createProfileOperations';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

const ClientsConfig = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clients = useAppSelector(getClients);

  useEffect(() => {
    dispatch(retrieveClients(null));

    const handleModalClose = () => {
      window.location.reload();
    };

    window.addEventListener('modalClose', handleModalClose);

    return () => {
      window.removeEventListener('modalClose', handleModalClose);
    };
  }, [dispatch]);

  const AddClient = () => {
    dispatch(
      setModalProps({
        radius: '18px',
      })
    );
    dispatch(openModal());
    dispatch(setContent('AddClientModal'));
  };

  const DeleteClient = (clientId: number) => {
    dispatch(
      setModalProps({
        radius: '18px',
        title: 'client',
        apiValue: clientId,
        anyIsStatement: 'true',
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  const handleSubmit = () => {
    navigate(ROUTES.SERVICES_CONFIG);
  };

  const handleBack = () => {
    navigate(ROUTES.TEAM_MEMBERS_CONFIG);
  };

  return (
    <WrapperClientConfig>
      <Box display="flex" flexDirection="column" gap="35px">
        <Box display="flex" flexDirection="column" gap="5px">
          <Title>Add Clients</Title>
          <SubTitle>Add your clients' details to the dashboard</SubTitle>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          padding="0px 2.5%"
        >
          <ElementBox
            width="100%"
            height="44px"
            title="Add Client"
            functionality="Add"
            onClickFunctionality={AddClient}
          />
          {Array.isArray(clients) &&
            clients.map((client, index) => (
              <ElementBox
                key={index}
                width="100%"
                title={client.name}
                height="44px"
                functionality="Remove"
                onClickFunctionality={() => DeleteClient(client.id)}
              />
            ))}
        </Box>
      </Box>

      <ButtonsContainer
        titleButton="Next"
        onClickBack={handleBack}
        onClick={handleSubmit}
      />
    </WrapperClientConfig>
  );
};

export default ClientsConfig;
