import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { ISTOK_WEB } from 'constants/fonts';

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  width: 'fit-content',
  textAlign: 'center',
  padding: '5px',
  background: '#FFF7DE',
  borderRadius: '12px',
  border: '1px solid #FFE9AF',
  color: theme.palette.text.disabled,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.352px',
  '&::before': {
    content: "''",
    position: 'absolute',
    top: '-8px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderBottom: '14px solid #FFF7DE',
  },
}));
