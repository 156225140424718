import { Box, BoxProps, styled } from '@mui/material';

interface ILogoProps extends BoxProps {
  src: string;
}

export const WrapperHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '25px',
  width: '100%',
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
}));
