import { RootState } from 'reduxStore/store';

const getHuntressGeneralStatistics = (state: RootState) =>
  state.toolHuntress.generalStatistics;
const getHuntressSelectedOrgName = (state: RootState) =>
  state.toolHuntress.selectedOrgName;
const getHuntressSelectedOrgId = (state: RootState) =>
  state.toolHuntress.selectedOrgId;
const getHuntressAgents = (state: RootState) => state.toolHuntress.agents;
const getHuntressIncidentRefs = (state: RootState) =>
  state.toolHuntress.incidentRefs;
const getHuntressIncidents = (state: RootState) => state.toolHuntress.incidents;
const getHuntressReportRefs = (state: RootState) =>
  state.toolHuntress.reportRefs;
const getHuntressReports = (state: RootState) => state.toolHuntress.reports;
const getHuntressError = (state: RootState) => state.toolHuntress.error;

export {
  getHuntressGeneralStatistics,
  getHuntressSelectedOrgName,
  getHuntressSelectedOrgId,
  getHuntressAgents,
  getHuntressIncidentRefs,
  getHuntressIncidents,
  getHuntressReportRefs,
  getHuntressReports,
  getHuntressError,
};
