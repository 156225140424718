import { CLIENTS_TABLE } from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { retrieveClients } from 'reduxStore/createProfile/createProfileOperations';
import { getClients } from 'reduxStore/createProfile/createProfileSelectors';
import { retrieveOrganization } from 'reduxStore/organization/organizationOperations';
import { ROUTES } from 'routes/routes.const';
import { TableBasis } from 'ui';

const ClientsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clients = useAppSelector(getClients);

  useEffect(() => {
    dispatch(retrieveClients(null));
  }, [dispatch]);

  const handleAddClient = () => {
    navigate(ROUTES.ADD_CLIENT);
  };

  const reformatedClients = Array.isArray(clients)
    ? clients.map(client => {
        return {
          name: client.name,
          dev_count: client.devices_count,
          admin_count: client.admins_count,
          alert_count: client.alerts_count,
          tool_count: client.tools_count,
          icon: client.icon || '',
          id: client.id,
        };
      })
    : [];

  const handleUpdateClient = async (row: any) => {
    try {
      await dispatch(retrieveOrganization(row.id)).unwrap();
      navigate(ROUTES.UPDATE_CLIENT);
    } catch (error) {
      return;
    }
  };

  return (
    <TableBasis
      tableData={reformatedClients}
      columns={CLIENTS_TABLE}
      title="Clients"
      icon="edit"
      showPictures={true}
      addAction="true"
      onClick={handleUpdateClient}
      handleAdd={handleAddClient}
    />
  );
};

export default ClientsList;
