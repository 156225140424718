import { Wrapper } from './ClientsPage.styled';
import { Navigate, Outlet, useLocation } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { HeaderWithSearch } from 'ui';

const ClientsPage = () => {
  const location = useLocation();

  // Redirect if the user visits /clients directly
  if (location.pathname === ROUTES.CLIENTS) {
    return <Navigate to={ROUTES.CLIENTS_LIST} replace />;
  }
  return (
    <Wrapper>
      <HeaderWithSearch />
      <Outlet />
    </Wrapper>
  );
};

export default ClientsPage;
