import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { getAssignableRoles } from 'reduxStore/createProfile/createProfileSelectors';
import {
  MenuItemBox,
  MenuItemText,
  Title,
  Unassign,
  WrapperAssignmentConfig,
} from './AssignmentConfig.styled';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { retrieveAssignableRoles } from 'reduxStore/createProfile/createProfileOperations';

interface IElementBoxProps {
  title: string;
  width?: string;
  height?: string;
  orgId: number;
  contactEmail: string;
  roleId?: number;
}

const AssignmentConfig = ({
  title,
  width,
  height,
  orgId,
  contactEmail,
  roleId,
}: IElementBoxProps) => {
  const dispatch = useAppDispatch();

  const assignableRoles = useAppSelector(getAssignableRoles);

  const [assignedRole, setAssignedRole] = useState<number>(roleId || 0);

  useEffect(() => {
    dispatch(retrieveAssignableRoles(null));
  }, [dispatch]);

  const handleChange = (event: SelectChangeEvent<number>) => {
    setAssignedRole(event.target.value as number);
  };

  return (
    <WrapperAssignmentConfig width={width} height={height}>
      <Title>{title}</Title>
      <FormControl fullWidth sx={{ width: '40%' }}>
        <Select
          value={assignedRole}
          onChange={handleChange}
          sx={{ height: '40px' }}
        >
          <MenuItem value={0} style={{ display: 'none' }}>
            <MenuItemText>Select Role</MenuItemText>
          </MenuItem>
          {assignableRoles.map((role, index) => (
            <MenuItem key={index} value={role.id} style={MenuItemBox}>
              <MenuItemText>{role.name}</MenuItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Unassign underline="none">Delete Assignment</Unassign>
    </WrapperAssignmentConfig>
  );
};

export default AssignmentConfig;
