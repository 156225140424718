import { object, string } from 'yup';

const createValidationSchema = () => {
  const schemaFields = {
    first_name: string()
      .min(2, 'Minimum 2 symbols are required')
      .required('First name is required'),
    last_name: string()
      .min(2, 'Minimum 2 symbols are required')
      .required('Last name is required'),
    email: string().email('Invalid email').required('Email is required'),
    role_name: string()
      .required('Role is required')
      .test('not-select', 'Role is required', value => value !== '0'),
  };

  return object(schemaFields);
};

export default createValidationSchema;
