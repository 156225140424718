import { styled } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

interface ILogoProps {
  width?: string;
  height?: string;
}

export const StyledLogo = styled(Logo)<ILogoProps>(({ width, height }) => ({
  cursor: 'pointer',
  margin: '0px',
  width: width ? width : '133px',
  height,
}));
