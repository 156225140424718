import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { ISTOK_WEB } from 'constants/fonts';

interface IButtonUploadProps extends ButtonProps {
  width?: string;
  height?: string;
  onClick?: () => void;
}

export const CustomButtonUpload = styled(Button)<IButtonUploadProps>(
  ({ theme, width, height }) => ({
    width: width ? width : 'fit-content',
    height: height ? height : 'fit-content',
    padding: '7px 34px 6px 35px',
    color: theme.palette.primary.dark,
    fontFamily: ISTOK_WEB,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    borderRadius: '17px',
    border: '1px solid #B5B5B5',
    background: theme.palette.action.disabled,
  })
);
