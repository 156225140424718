import { FlatButton } from 'ui';
import {
  Buttons,
  IconAndTitle,
  Title,
  WrapperAccountSettingsChangeConfirm,
} from './AccountSettingsChangeConfirm.styled';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'hooks';
import { ROUTES } from 'routes/routes.const';
import { closeModal } from 'reduxStore/modal/modalSlice';
import InfoIcon from '@mui/icons-material/Info';
import { retrieveAccountInfo } from 'reduxStore/organization/organizationOperations';

const AccountSettingsChangeConfirm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLeave = async () => {
    await dispatch(retrieveAccountInfo(null)).unwrap();
    navigate(ROUTES.DASHBOARD);
    dispatch(closeModal());
  };

  const handleStay = async () => {
    await dispatch(retrieveAccountInfo(null)).unwrap();
    dispatch(closeModal());
  };

  return (
    <WrapperAccountSettingsChangeConfirm>
      <IconAndTitle>
        <InfoIcon
          sx={{
            fontSize: '50px',
            color: '#868686',
          }}
        />
        <Title>Your account information has been updated</Title>
      </IconAndTitle>

      <Buttons>
        <FlatButton
          onClick={handleStay}
          variant="outlined"
          width="223px"
          height="59px"
          borderradius="12px"
        >
          Stay on page
        </FlatButton>
        <FlatButton
          onClick={handleLeave}
          variant="contained"
          width="223px"
          height="59px"
          borderradius="12px"
        >
          Back to home
        </FlatButton>
      </Buttons>
    </WrapperAccountSettingsChangeConfirm>
  );
};
export default AccountSettingsChangeConfirm;
