import {
  Box,
  BoxProps,
  Select,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER, ISTOK_WEB, POPPINS } from 'constants/fonts';
import { SelectFieldProps } from 'material-ui';
import { COLORS } from 'theme/colors.const';

interface ILogoProps extends BoxProps {
  src: string;
}
interface ISelectFieldProps extends SelectFieldProps {
  haserror?: 'true';
}

export const WrapperHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '33px',
}));

export const WrapperTopOfHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const WrapperSelectServiceAndOrg = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
}));

export const IconsBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '25px',
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
}));

export const SelectField = styled(Select)<ISelectFieldProps>(
  ({ theme, haserror }) => ({
    width: '285px',
    height: '54px',
    borderRadius: '43px',
    border: haserror
      ? `1px solid ${COLORS.error}`
      : `1px solid ${COLORS.oceanBlue}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    '& .MuiInputBase-input': {
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.text.secondary,
      fontSize: '14px',
      fontFamily: INTER,
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      background: theme.palette.background.paper,
      '&.MuiOutlinedInput-input': {
        border: haserror
          ? `1px solid ${COLORS.error}`
          : `1px solid ${COLORS.oceanBlue}`,
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: haserror
          ? `1px solid ${COLORS.error}`
          : `1px solid ${COLORS.oceanBlue}`,
      },
    },
    '&:hover': {
      border: haserror
        ? `1px solid ${COLORS.error}`
        : `1px solid ${COLORS.oceanBlue}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: haserror
          ? `1px solid ${COLORS.error}`
          : `1px solid ${COLORS.oceanBlue}`,
      },
    },
  })
);

export const MenuItemText = styled(Typography)(() => ({
  color: COLORS.black,
  fontFamily: POPPINS,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const MenuItemBox = {
  display: 'flex',
  justifyContent: 'center',
};

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '20px',
  lineHeight: '150%',
  fontWeight: '500',
  marginTop: '10px',
  letterSpacing: '-0.352px',
}));
