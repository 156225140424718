import { object, string } from 'yup';

const createValidationSchema = () => {
  const schemaFields = {
    name: string().required('Company Name is required'),
    street: string().required('Street is required'),
    city: string()
      .matches(
        /^[A-Za-z ]+$/,
        'City must consist of Latin letters and spaces only'
      )
      .required('City is required'),
    state: string()
      .matches(
        /^[A-Za-z ]+$/,
        'State must consist of Latin letters and spaces only'
      )
      .required('State is required'),
    zipCode: string()
      .matches(/^[0-9]{5}$/, 'Zip code must be exactly 5 digits')
      .required('Zip code is required'),
  };

  return object(schemaFields);
};

export default createValidationSchema;
