import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperClientConfig = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  component: 'section',
  position: 'relative',
  minHeight: '60vh',
  justifyContent: 'space-between',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '48px',
  letterSpacing: '-0.701px',
}));

export const SubTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.317px',
}));
