import { useLocation, useNavigate } from 'react-router-dom';
import { SectionTitle, Title, WrapperGeneral } from './ServicePage.styled';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  retrieveClients,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClients,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect } from 'react';
import { TableBasis } from 'ui';
import { TOOL_CLIENTS_TABLE } from 'constants/tablesHead';
import { Box } from '@mui/material';
import { FlatButton } from 'ui';
import { ROUTES } from 'routes/routes.const';
import {
  getMainOrganization,
  getMainOrganizationID,
} from 'reduxStore/organization/organizationSelectors';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/toolHuntress/toolHuntressOperations';
import {
  setSelectedOrgId,
  setSelectedOrgName,
} from 'reduxStore/toolHuntress/toolHuntressSlice';

const ServicePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    toolId: number;
  };
  const dispatch = useAppDispatch();
  const tools = useAppSelector(getTools);
  const neededTool = tools.find(tool => tool.id === state.toolId);
  const toolClients = useAppSelector(getClients);
  const organizationId = useAppSelector(getMainOrganizationID);
  const mainOrgName = useAppSelector(getMainOrganization)?.name;
  const alertsSum = toolClients.reduce(
    (sum, client) => sum + client.alerts_count,
    0
  );

  useEffect(() => {
    dispatch(retrieveTools(null));
    dispatch(retrieveClients(state.toolId));
  }, [dispatch, state.toolId]);

  const formattedClients = toolClients.map(client => {
    return {
      name: client.name,
      dev_count: client.devices_count,
      alert_count: client.alerts_count,
    };
  });

  //TODO: change in the future for different services and pass id of particular organization here
  const handleOpenServiceForMSP = async (selectedOrgId: number) => {
    if (neededTool?.name === 'Huntress') {
      try {
        await dispatch(
          retrieveHuntressGeneralStatistics(selectedOrgId)
        ).unwrap();
        dispatch(setSelectedOrgName(mainOrgName));
        dispatch(setSelectedOrgId(selectedOrgId));
        navigate(ROUTES.HUNTRESS_DASHBOARD);
      } catch (error) {
        return;
      }
    }
  };

  const handleOpenServiceForClient = async (row: any) => {
    const neededClient = toolClients.find(
      toolClient => toolClient.name === row.name
    );
    if (neededTool?.name === 'Huntress') {
      if (typeof neededClient?.id === 'number') {
        try {
          await dispatch(
            retrieveHuntressGeneralStatistics(neededClient.id)
          ).unwrap();
          dispatch(setSelectedOrgName(neededClient.name));
          dispatch(setSelectedOrgId(neededClient.id));
          navigate(ROUTES.HUNTRESS_DASHBOARD);
        } catch (error) {
          return;
        }
      }
    }
  };

  return (
    <WrapperGeneral>
      <Title>{neededTool?.name}</Title>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <SectionTitle>
          General info: Devices - {neededTool?.devices_count}, Connected Clients
          - {neededTool?.clients_count}, Alerts - {alertsSum}
        </SectionTitle>
        <FlatButton
          variant="contained"
          width="150px"
          onClick={() => handleOpenServiceForMSP(organizationId)}
        >
          Visit MSP Page
        </FlatButton>
      </Box>

      <TableBasis
        tableData={formattedClients}
        columns={TOOL_CLIENTS_TABLE}
        title={`${neededTool?.name} Clients`}
        icon="edit"
        onClick={handleOpenServiceForClient}
      />
    </WrapperGeneral>
  );
};

export default ServicePage;
