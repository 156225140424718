import Tab from '@mui/material/Tab';
import { CustomLink, WrapperTabs } from './TextTabs.styled';
import { SyntheticEvent, useEffect, useState } from 'react';
import { initiateTabValueForNavTabs } from 'utils';
import { useLocation } from 'react-router-dom';

interface ITextTabsProps {
  width: string;
  height: string;
  options: { title: string; url: string }[];
  hierarchy: 'parent' | 'child';
}

const TextTabs = ({ width, height, options, hierarchy }: ITextTabsProps) => {
  const { pathname } = useLocation();

  const initiateValue = () =>
    initiateTabValueForNavTabs(options, pathname, true);

  const [value, setValue] = useState(initiateValue);

  useEffect(() => {
    if (pathname !== '/') {
      const parentPath =
        hierarchy === 'parent'
          ? pathname.split('/').filter(String)[1]
          : pathname.split('/').filter(String)[2];

      const parentIndex = options.findIndex(i => {
        const elementURL =
          hierarchy === 'parent'
            ? i.url.split('/').filter(String)[1]
            : i.url.split('/').filter(String)[2];
        return elementURL === parentPath;
      });
      setValue(parentIndex !== -1 ? parentIndex : prevState => prevState);
    }
    if (pathname === '/') {
      setValue(0);
    }
  }, [pathname, options, hierarchy]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <WrapperTabs
      width={width}
      height={height}
      value={value}
      onChange={handleChange}
    >
      {options.map(({ title, url }, index) => (
        <Tab
          key={`${hierarchy}tab-${index}`}
          label={title}
          component={CustomLink}
          to={url}
        />
      ))}
    </WrapperTabs>
  );
};

export default TextTabs;
