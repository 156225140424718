import { ActionsDropdown, IconButton, RoundButton } from 'ui';
import {
  Error,
  LogoBox,
  OrganizationCounter,
  OrganizationName,
  SelectField,
  SelectorInfoContainer,
  SelectorRenderWrapper,
  ServiceName,
  ServiceOrgsList,
  Wrapper,
  WrapperServiceOrgs,
} from './ServicesListPage.styled';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import {
  deleteIntegration,
  retrieveClients,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClients,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Box, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  getAdminInfo,
  getMainOrganization,
  getMainOrganizationID,
} from 'reduxStore/organization/organizationSelectors';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/toolHuntress/toolHuntressOperations';
import {
  resetHuntressAllData,
  setSelectedOrgId,
  setSelectedOrgName,
} from 'reduxStore/toolHuntress/toolHuntressSlice';
import {
  getHuntressError,
  getHuntressSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import { IActionsProps } from 'types/selectItem.type';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  IClientStatisticBody,
  IDeleteIntgrnBody,
  ITool,
  IToolOrg,
} from 'types/api.type';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrganizationElement } from './components';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

interface IServiceAndConnectedClients {
  expanded: boolean;
  service: ITool;
  orgs: IToolOrg[];
}

//TODO: PUT HERE HeaderServices to Header
const ServicesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const mainOrgId = useAppSelector(getMainOrganizationID);
  const mainOrg = useAppSelector(getMainOrganization);
  const admin = useAppSelector(getAdminInfo);
  const tools = useAppSelector(getTools);
  const clients = useAppSelector(getClients);
  const error = useAppSelector(getHuntressError);
  const selectedOrgId = useAppSelector(getHuntressSelectedOrgId);
  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  const [selectedTool, setSelectedTool] = useState<number>(1);
  const [selectedOrg, setSelectedOrg] = useState<number>(selectedOrgId);
  const [isSelectActive, setIsSelectActive] = useState(false);

  const actions: IActionsProps[] = [
    {
      icon: <SettingsOutlinedIcon fontSize="small" />,
      title: 'Settings',
      onClick: () => {
        navigate(ROUTES.ACCOUNT_SETTINGS);
      },
    },
    {
      icon: <LogoutIcon fontSize="small" />,
      title: 'Logout',
      onClick: handleLogout,
    },
  ];

  const connectedTools = tools.filter(
    tool =>
      (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
      (tool.clients_count !== null && tool.clients_count !== 0)
  );

  const orgs = clients.map(client => {
    return { id: client.id, name: client.name };
  });
  orgs.unshift({ id: mainOrgId, name: mainOrg?.name });

  const [serviceClientRelationships, setServiceClientRelationships] = useState<
    IServiceAndConnectedClients[]
  >([]);

  const processRelationships = async (): Promise<
    IServiceAndConnectedClients[]
  > => {
    const promises = connectedTools.map(async connectedTool => {
      const toolClients = await dispatch(
        retrieveClients(connectedTool.id)
      ).unwrap();

      const toolOrgs = toolClients.clients.map(
        (client: IClientStatisticBody) => {
          return { id: client.id, name: client.name };
        }
      );
      toolOrgs.unshift({ id: mainOrgId, name: mainOrg?.name });

      return { expanded: false, service: connectedTool, orgs: toolOrgs };
    });

    const results = await Promise.all(promises);
    return results;
  };

  const relationshipInitialization = async () => {
    try {
      const results = await processRelationships();
      setServiceClientRelationships(results);
      setIsSelectActive(true);
    } catch (error) {
      return;
    }
  };

  const onOpen = () => {
    relationshipInitialization();
  };

  useEffect(() => {
    dispatch(retrieveTools(null));
    dispatch(retrieveClients(null));

    const handleModalClose = () => {
      window.location.reload();
    };

    window.addEventListener('modalClose', handleModalClose);

    return () => {
      window.removeEventListener('modalClose', handleModalClose);
    };
  }, [dispatch]);

  const handleOrgSelect = async (orgId: number, orgName: string) => {
    setSelectedOrg(orgId);

    if (tools.find(tool => tool.id === selectedTool)?.name === 'Huntress') {
      try {
        await dispatch(retrieveHuntressGeneralStatistics(orgId)).unwrap();
        setIsSelectActive(false);
        dispatch(setSelectedOrgName(orgName));
        dispatch(setSelectedOrgId(orgId));
        navigate(ROUTES.HUNTRESS_DASHBOARD);
      } catch (error) {
        return;
      }
    }
  };

  const handleToggle = async (toolId: number) => {
    const neededRelationship = serviceClientRelationships.find(
      relationship => relationship.service.id === toolId
    );

    if (neededRelationship) {
      neededRelationship.expanded === false && setSelectedTool(toolId);

      const reformatedRelationships = serviceClientRelationships.map(
        relationship =>
          relationship.service.id === toolId
            ? { ...relationship, expanded: !relationship.expanded }
            : relationship
      );
      setServiceClientRelationships(reformatedRelationships);
    }
  };

  const handleDisconnectOrganization = async (orgId: number) => {
    const body: IDeleteIntgrnBody = {
      tool_id: selectedTool,
      org_id: orgId,
      is_total: false,
    };
    try {
      await dispatch(deleteIntegration(body)).unwrap();
      setIsSelectActive(false);
      setSelectedOrg(0);
      navigate(ROUTES.SERVICES);
    } catch (error) {
      if (error === 'There are specific client integrations') {
        setIsSelectActive(false);
        dispatch(
          setModalProps({
            radius: '18px',
            title: 'integration',
            apiValue: body,
          })
        );
        dispatch(openModal());
        dispatch(setContent('DeleteConfirmation'));
      }

      return;
    }
  };

  const handleAddIntegration = () => {
    dispatch(resetHuntressAllData());
    setSelectedOrg(0);
    navigate(ROUTES.ADD_SERVICE);
  };

  if (location.pathname === ROUTES.SERVICES && connectedTools.length === 0) {
    return <Navigate to={ROUTES.ADD_SERVICE} replace />;
  }

  return (
    <Wrapper>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          {connectedTools.length > 0 && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="10px"
            >
              <SelectField
                open={isSelectActive}
                value={selectedTool}
                haserror={error ? 'true' : undefined}
                onOpen={onOpen}
                onClose={() => {
                  setIsSelectActive(false);
                }}
              >
                <MenuItem sx={{ display: 'none' }} value={selectedTool}>
                  <SelectorRenderWrapper>
                    <LogoBox
                      src={
                        tools.find(tool => tool.id === selectedTool)?.icon || ''
                      }
                      width="42px"
                      height="42px"
                    />
                    <SelectorInfoContainer>
                      <ServiceName>
                        {tools.find(tool => tool.id === selectedTool)?.name}
                      </ServiceName>
                      <OrganizationName>
                        {orgs.find(org => org.id === selectedOrg)?.name}
                      </OrganizationName>
                    </SelectorInfoContainer>
                  </SelectorRenderWrapper>
                </MenuItem>
                {serviceClientRelationships.map((relationship, index) => (
                  <WrapperServiceOrgs key={index}>
                    <ServiceOrgsList
                      onClick={() => {
                        handleToggle(relationship.service.id);
                      }}
                    >
                      <Box>
                        <LogoBox
                          src={relationship.service.icon}
                          width="42px"
                          height="42px"
                        />
                      </Box>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <OrganizationName>
                          {relationship.service.name}
                        </OrganizationName>
                        <OrganizationCounter>
                          {relationship.orgs.length}
                        </OrganizationCounter>
                      </Box>
                      {relationship.expanded ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ServiceOrgsList>
                    {relationship.expanded && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="5px"
                        marginTop="9px"
                        padding="8px"
                      >
                        {relationship.orgs.map((org, index) => (
                          <span
                            role="button"
                            key={index}
                            onClick={() => handleOrgSelect(org.id, org.name)}
                            tabIndex={0}
                          >
                            <OrganizationElement
                              orgName={org.name}
                              onDisconnect={() =>
                                handleDisconnectOrganization(org.id)
                              }
                            />
                          </span>
                        ))}
                      </Box>
                    )}
                  </WrapperServiceOrgs>
                ))}
              </SelectField>
            </Box>
          )}
          <RoundButton
            width="52px"
            height="52px"
            type="submit"
            variant="contained"
            setExpansion={connectedTools.length > 0 ? undefined : 'true'}
            needsExpansion={true}
            expansionText="Create Integration"
            onClick={handleAddIntegration}
          >
            <AddIcon fontSize="medium" />
          </RoundButton>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" gap="25px">
          <IconButton>
            <NotificationsNoneOutlinedIcon fontSize="medium" />
          </IconButton>
          <ActionsDropdown actions={actions}>
            {admin?.icon ? (
              <LogoBox src={admin.icon} />
            ) : (
              <AccountCircleOutlinedIcon fontSize="medium" />
            )}
          </ActionsDropdown>
        </Box>
      </Box>
      <Error>
        {error
          ? `${error} between ${tools.find(tool => tool.id === selectedTool)?.name} and ${orgs.find(org => org.id === selectedOrg)?.name}`
          : ''}
      </Error>
      <Outlet />
    </Wrapper>
  );
};

export default ServicesListPage;
