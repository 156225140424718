import { styled } from '@mui/material';
import Table, { TableProps } from '@mui/material/Table';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer';
import TableHead, { TableHeadProps } from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import { INTER, ISTOK_WEB } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface ITableContainerProps extends TableContainerProps {
  maxheight?: string;
  iswarning?: 'true';
}
interface ITableRowProps extends TableRowProps {
  ischeckbox?: 'true';
  isicon?: 'true';
}

interface ILogoProps extends BoxProps {
  src: string;
}

export const StyledTableContainer = styled(
  TableContainer
)<ITableContainerProps>(({ theme, maxheight, iswarning }) => ({
  position: 'relative',
  border: iswarning ? `2px solid ${theme.palette.error.light}` : 'none',
  background: theme.palette.common.white,
  width: '100%',
  height: '100%',
  padding: '19px 0 0',
  overflowY: maxheight ? 'auto' : 'visible',
  overflowX: 'visible',
  scrollBehavior: 'smooth',
  '& .MuiTableHead-root': {
    borderBottom: 'none',
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
    },
  },
  '& .MuiTableRow-root': {
    paddingLeft: '40px',
    paddingRight: '0px',
    '&:nth-of-type(even)': {
      background: theme.palette.common.white,
    },
    '&:nth-of-type(odd)': {
      background: theme.palette.common.white,
    },
    '&:hover': {
      //backgroundColor: type === SUPER_ADMIN && '#005A8D',
    },
  },
  '& .MuiTableCell-root': {
    fontSize: '16px',
    fontFamily: ISTOK_WEB,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    color: theme.palette.secondary.light,
  },
  '& .MuiTableCell-paddingCheckbox:first-of-type': {
    width: '24px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '247px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.secondary.contrastText,
    borderRadius: '17px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
  },
}));

export const WrapperTable = styled(Box)<BoxProps>(() => ({
  paddingBottom: '19px',
  background: 'none',
  width: '100%',
  minHeight: '100%',
}));

export const StyledTable = styled(Table)<TableProps>(() => ({
  borderSpacing: '0px 9px',
  borderCollapse: 'separate',
  backgroundColor: COLORS.snowBlue,
}));

export const StyledTableHead = styled(TableHead)<TableHeadProps>(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    marginBottom: '19px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '& .MuiTableCell-head:last-of-type': {
      textAlign: 'end',
    },
    '& .MuiTableCell-head:first-of-type': {
      textAlign: 'start',
    },
    '& .MuiTableCell-head': {
      fontFamily: INTER,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      textTransform: 'uppercase',
    },
  })
);

export const StyledTableRow = styled(TableRow)<ITableRowProps>(
  ({ ischeckbox, isicon }) => ({
    height: '36px',
    cursor: 'pointer',
    padding: '0 ',
    '& .MuiTableCell-head:nth-of-type(2)': {
      textAlign: ischeckbox ? 'start' : 'center',
    },
    '& .MuiTableCell-root:nth-of-type(2)': {
      textAlign: ischeckbox ? 'start' : 'center',
    },
    '& .MuiTableCell-root:last-of-type': {
      width: isicon ? '120px' : 'inherit',
      minWidth: isicon && '80px',
    },
    '& .MuiTableCell-root:first-of-type': {
      minWidth: ischeckbox && '48px',
    },
  })
);

export const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  fontWeight: '400',
  border: 'none',
  marginLeft: 0,
  padding: '3px',
  '&:not(:first-of-type)': {
    textAlign: 'center',
  },
  '&:last-of-type': {
    textAlign: 'end',
    paddingRight: '40px',
  },
  '&:first-of-type': {
    textAlign: 'start',
    paddingLeft: '40px',
  },
}));

export const StyledWrapperTitle = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  boxSizing: 'border-box',
  marginBottom: '20px',
  paddingLeft: '40px',
  paddingRight: '40px',
}));

export const StyledTypography = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondary,
  fontFamily: INTER,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const StyledActionBlock = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: 0,
  right: '40px',
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
}));

export const LogoBox = styled(Box)<ILogoProps>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
}));
