import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';

interface IRoundButtonProps extends ButtonProps {
  variant?: 'contained' | 'outlined';
  width?: string;
  height?: string;
  ishovered?: 'true';
  needsexpansion?: 'true';
}

export const StyledRoundButton = styled(Button)<IRoundButtonProps>(
  ({ theme, variant, width, height, ishovered, needsexpansion }) => ({
    display: 'flex',
    justifyContent:
      ishovered === 'true' && needsexpansion === 'true'
        ? 'space-between'
        : 'center',
    alignItems: 'center',
    gap: ishovered === 'true' && needsexpansion === 'true' ? '10px' : '',
    minWidth: width,
    width:
      ishovered === 'true' && needsexpansion === 'true'
        ? 'feet-content'
        : width,
    height,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.352px',
    borderRadius:
      ishovered === 'true' && needsexpansion === 'true' ? '50px' : '50%',
    border: 'none',
    color:
      variant === 'contained'
        ? theme.palette.common.white
        : theme.palette.secondary.light,
    background:
      variant === 'contained'
        ? theme.palette.primary.main
        : theme.palette.action.disabled,
    overflow: 'hidden',
    transition:
      ishovered === 'true' && needsexpansion === 'true'
        ? `width 0.1s ease, border-radius 0s`
        : '',
    '&:hover': {
      border: 'none',
      borderRadius:
        ishovered === 'true' && needsexpansion === 'true' ? '50px' : '50%',
      justifyContent:
        ishovered === 'true' && needsexpansion === 'true'
          ? 'space-between'
          : 'center',
      color:
        variant === 'contained'
          ? theme.palette.common.white
          : theme.palette.secondary.light,
      background:
        variant === 'contained'
          ? theme.palette.primary.main
          : theme.palette.action.disabled,
    },
  })
);

export const HoverText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '-0.352px',
  color: theme.palette.common.white,
}));
