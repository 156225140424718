import { useRef } from 'react';
import {
  UploadImageWrapper,
  Circle,
  ErrorMessage,
  DeleteIconButton,
  DeleteIcon,
} from './SectionUploadSmall.styled';
import { ButtonUpload } from 'ui';
import { InputInvisible } from 'ui/inputs';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box } from '@mui/material';

interface ISectionUploadProps {
  handleUpload: (image: File, error?: string | null) => Promise<void>;
  onDelete?: () => void;
  imageUrl: string;
  error?: string | null;
  notAvailableToEdit?: 'true';
  title: string;
}

const SectionUploadSmall = ({
  handleUpload,
  onDelete,
  imageUrl,
  error,
  notAvailableToEdit,
  title,
}: ISectionUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    if (inputRef.current && !notAvailableToEdit) {
      inputRef.current.click();
    }
  };

  const handleDeleteImage = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onDelete?.();
  };

  return (
    <UploadImageWrapper>
      <Box
        sx={{
          position: 'relative',
          '&:hover #delete-icon': {
            opacity: 0.7,
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            borderRadius: '50%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Dim color overlay
            transition: 'opacity 0.3s ease',
            pointerEvents: 'none', // Make sure the overlay doesn't block interactions
            opacity: 0,
          },

          '&:hover::before': {
            opacity: !notAvailableToEdit && imageUrl ? 1 : 0, // Show the dim effect on hover
          },
          width: '59px',
          height: '59px',
        }}
      >
        <Circle id="image-wrapper">
          {imageUrl ? (
            <img
              style={{ objectFit: 'cover' }}
              src={imageUrl}
              alt="Company profile"
            />
          ) : (
            <PhotoCameraIcon color="secondary" fontSize="large" />
          )}
          {!notAvailableToEdit && imageUrl && (
            <DeleteIconButton
              id="delete-icon"
              disableFocusRipple={false}
              onClick={handleDeleteImage}
            >
              <DeleteIcon />
            </DeleteIconButton>
          )}
        </Circle>
      </Box>
      <InputInvisible
        ref={inputRef}
        accept="image/jpeg, image/png, image/gif"
        onUpload={handleUpload}
      />
      <ButtonUpload onClick={handleClickUpload} width="499px" height="59px">
        {title}
      </ButtonUpload>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </UploadImageWrapper>
  );
};

export default SectionUploadSmall;
