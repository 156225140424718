import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getHuntressGeneralStatistics,
  getHuntressSelectedOrgId,
} from 'reduxStore/toolHuntress/toolHuntressSelectors';
import { WrapperChildren } from './HuntressDashboardPage.styled';
import { DataBox, TextTabs } from 'ui';
import { Navigate, Outlet, useLocation } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useEffect } from 'react';
import { retrieveHuntressGeneralStatistics } from 'reduxStore/toolHuntress/toolHuntressOperations';
import { COLORS } from 'theme/colors.const';
import { theme } from 'theme';
import { Box } from '@mui/material';

const HuntressDashboardPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const generalStatistics = useAppSelector(getHuntressGeneralStatistics);
  const selectedOrgId = useAppSelector(getHuntressSelectedOrgId);

  const activeIncidentsData = [
    {
      title: 'Critical',
      amount: generalStatistics?.incidents.sent.crit_count || 0,
      color: theme.palette.error.main,
    },
    {
      title: 'High',
      amount: generalStatistics?.incidents.sent.high_count || 0,
      color: COLORS.yellowDark,
    },
    {
      title: 'Low',
      amount: generalStatistics?.incidents.sent.low_count || 0,
      color: COLORS.greenDark,
    },
  ];

  const agentsData = [
    {
      title: 'Protected',
      amount: generalStatistics?.agents.protected_count || 0,
      color: COLORS.superBlue,
    },
    {
      title: 'Outdated',
      amount: generalStatistics?.agents.outdated_count || 0,
      color: theme.palette.error.main,
    },
    {
      title: 'Unstable',
      amount: generalStatistics?.agents.unresponsive_count || 0,
      color: COLORS.yellowDark,
    },
    {
      title: 'Isolated',
      amount: generalStatistics?.agents.isolated_count || 0,
      color: COLORS.greenDark,
    },
  ];

  const antivirusData = [
    {
      title: 'Protected',
      amount: generalStatistics?.defenders.protected_count || 0,
      color: COLORS.superBlue,
    },
    {
      title: 'Unmanaged',
      amount: generalStatistics?.defenders.unmanaged_count || 0,
      color: theme.palette.error.main,
    },
    {
      title: 'Unhealthy',
      amount: generalStatistics?.defenders.unhealthy_count || 0,
      color: COLORS.yellowDark,
    },
    {
      title: 'Incomplete',
      amount: generalStatistics?.defenders.incompatible_count || 0,
      color: COLORS.greenDark,
    },
  ];

  const navOptions = [
    {
      title: 'Agents',
      url: ROUTES.HUNTRESS_DASHBOARD_AGENTS,
    },
    {
      title: 'Incidents',
      url: ROUTES.HUNTRESS_DASHBOARD_INCIDENTS,
    },
    {
      title: 'Reports',
      url: ROUTES.HUNTRESS_DASHBOARD_REPORTS,
    },
  ];

  useEffect(() => {
    dispatch(retrieveHuntressGeneralStatistics(selectedOrgId));
  }, [dispatch, selectedOrgId]);

  // Redirect if the user visits /huntress_dashboard directly
  if (location.pathname === ROUTES.HUNTRESS_DASHBOARD) {
    return <Navigate to={ROUTES.HUNTRESS_DASHBOARD_AGENTS} replace />;
  }
  return (
    <Box display="flex" flexDirection="column" gap="20px" marginTop="20px">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <DataBox
          title="Incidents"
          data={activeIncidentsData}
          width="35%"
          height="30vh"
        />
        <DataBox
          title="Antivirus"
          data={antivirusData}
          width="30%"
          height="30vh"
          needsGraph="true"
        />
        <DataBox
          title="Agents"
          data={agentsData}
          width="30%"
          height="30vh"
          needsGraph="true"
        />
      </Box>
      <WrapperChildren>
        <TextTabs
          height="20px"
          width="300px"
          options={navOptions}
          hierarchy="child"
        />

        <Outlet />
      </WrapperChildren>
    </Box>
  );
};

export default HuntressDashboardPage;
