import React from 'react';
import { CustomButtonUpload } from './ButtonUpload.styled';
import { ButtonProps } from '@mui/material';

interface IButtonUploadProps extends ButtonProps {
  width?: string;
  height?: string;
  onClick?: () => void;
  children?: React.ReactNode | string;
}

const ButtonUpload = ({
  width,
  height,
  onClick,
  children,
}: IButtonUploadProps) => {
  return (
    <CustomButtonUpload width={width} height={height} onClick={onClick}>
      {children ? children : 'Upload new'}
    </CustomButtonUpload>
  );
};

export default ButtonUpload;
