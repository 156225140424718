import React from 'react';
import {
  WrapperAddIntegrationModal,
  Title,
  MenuItemText,
  MenuItemBox,
  Error,
} from './AddIntegrationModal.styled';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import {
  createIntegration,
  checkAPICredentials,
  retrieveTools,
  retrieveToolOrgs,
  retrieveClientsRef,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClientsRef,
  getCreateProfileError,
  getToolOrgs,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { FlatButton, Input } from 'ui';
import {
  IAuthData,
  ICreateIntegrationBody,
  IReceiveToolOrgsBody,
} from 'types/api.type';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import {
  getMainOrganization,
  getMainOrganizationID,
} from 'reduxStore/organization/organizationSelectors';

interface IServiceFormValue {
  tool_id: number;
  intgr_level: number;
  token: string;
  code: string;
  secret: string;
  server: string;
  type: number;
  client_id: number | null;
  tool_org_id: number | null;
  checked_API: boolean;
}

const validationSchema = Yup.object({
  tool_id: Yup.string()
    .required('Tool is required')
    .test('not-select', 'Tool is required', value => value !== '0'),
  intgr_level: Yup.string()
    .required('Integration Level is required')
    .test(
      'not-select',
      'Please select integration level',
      value => value !== '0'
    ),
  // TODO: Make Checking API mandatory in case of using API credentials
  // checked_API: Yup.string()
  //   .required('Check API')
  //   .test('not-select', 'Check API', value => value !== 'false'),
});

const AddIntegrationModal = () => {
  const dispatch = useAppDispatch();

  const clientsAsRefs = useAppSelector(getClientsRef);
  const tools = useAppSelector(getTools);

  const [selectedIntgrType, setSelectedIntgrType] = useState<number>(0);
  const [selectedTool, setSelectedTool] = useState<number>(0);

  const toolOrgs = useAppSelector(getToolOrgs);
  const error = useAppSelector(getCreateProfileError);

  const organizationId = useAppSelector(getMainOrganizationID);
  const mainOrgName = useAppSelector(getMainOrganization)?.name;

  useEffect(() => {
    dispatch(retrieveClientsRef(null));
    dispatch(retrieveTools(null));
    const fetchToolOrgs = async () => {
      try {
        await dispatch(retrieveToolOrgs(selectedTool)).unwrap();
      } catch (error) {
        return;
      }
    };
    if (selectedIntgrType === 1 && selectedTool !== 0) {
      fetchToolOrgs();
    }
  }, [dispatch, selectedIntgrType, selectedTool]);

  const initialValues: IServiceFormValue = {
    tool_id: 0,
    intgr_level: 0,
    token: '',
    code: '',
    secret: '',
    server: '',
    type: 0,
    client_id: 0,
    tool_org_id: 0,
    checked_API: false,
  };

  const handleSubmit = async (value: IServiceFormValue) => {
    const nameOfOrg =
      value.intgr_level === 1
        ? mainOrgName
        : clientsAsRefs.find(client => client.id === value.client_id)?.name;
    const body: ICreateIntegrationBody = {
      tool_id: value.tool_id,
      //TODO: Figure out how to fix if user enters API creds and then decides to use Client Level and vice versa
      org_id: value.intgr_level === 2 ? value.client_id : organizationId,
      auth_data: checkAuthMethod(value),
      tool_org_id:
        value.intgr_level === 2 && value.type === 1 ? value.tool_org_id : null,
    };
    try {
      await dispatch(createIntegration(body)).unwrap();
      dispatch(
        setModalProps({
          radius: '18px',
          title: nameOfOrg,
          additionalText: tools.find(tool => tool.id === value.tool_id)?.name,
        })
      );
      dispatch(openModal());
      dispatch(setContent('AddIntegrationConfirm'));
    } catch (error) {
      return;
    }
  };

  const handleAPICheck = async (value: IServiceFormValue) => {
    const body: IReceiveToolOrgsBody = {
      tool_id: value.tool_id,
      auth_data: checkAuthMethod(value),
    };

    try {
      await dispatch(checkAPICredentials(body)).unwrap();
    } catch (error) {
      return;
    }
  };

  const checkAuthMethod = (value: IServiceFormValue): IAuthData | null => {
    if (value.tool_org_id !== 0) return null;
    const auth_method = tools.find(tool => tool.id === value.tool_id)
      ?.auth_method.id;
    switch (auth_method) {
      case 1:
        return {
          basic: {
            code: value.code,
            secret: value.secret,
          },
        };
      case 2:
        return {
          bearer: {
            token: value.token,
          },
        };
      case 3:
        return {
          vsax: {
            server: value.server,
            code: value.code,
            secret: value.secret,
          },
        };
      default:
        return {};
    }
  };

  return (
    <WrapperAddIntegrationModal>
      <Title>Create Integration</Title>
      {!!error && <Error>{error}</Error>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="tool_id"
                  as={TextField}
                  id="tool_id"
                  label="Select Service"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.tool_id && Boolean(errors.tool_id)}
                  helperText={touched.tool_id && errors.tool_id}
                  InputLabelProps={{ htmlFor: 'tool_id', shrink: true }}
                  onChange={(e: React.ChangeEvent<{ value: number }>) => {
                    const value = e.target.value;
                    setFieldValue('tool_id', value);
                    setSelectedTool(value);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '59px',
                    },
                  }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Service</MenuItemText>
                  </MenuItem>
                  {Array.isArray(tools) &&
                    tools.map((tool, index) => (
                      <MenuItem key={index} value={tool.id} style={MenuItemBox}>
                        <MenuItemText>{tool.name}</MenuItemText>
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <Field
                  name="intgr_level"
                  as={TextField}
                  id="intgr_level"
                  label="Level Of Integration"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.intgr_level && Boolean(errors.intgr_level)}
                  helperText={touched.intgr_level && errors.intgr_level}
                  InputLabelProps={{ htmlFor: 'intgr_level', shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '59px',
                    },
                  }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Integration Level</MenuItemText>
                  </MenuItem>
                  <MenuItem value={1} style={MenuItemBox}>
                    <MenuItemText>MSP Level</MenuItemText>
                  </MenuItem>
                  <MenuItem value={2} style={MenuItemBox}>
                    <MenuItemText>Client Level</MenuItemText>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid container padding={2}>
                {values.intgr_level === 1 && (
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    {tools.find(tool => tool.id === values.tool_id)?.auth_method
                      .id === 1 && (
                      <>
                        <Grid item xs={8}>
                          <Field
                            component={Input}
                            name="code"
                            label="Code"
                            placeholder="Enter Code"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={Input}
                            name="secret"
                            label="Secret"
                            placeholder="Enter Secret"
                          />
                        </Grid>
                      </>
                    )}
                    {tools.find(tool => tool.id === values.tool_id)?.auth_method
                      .id === 2 && (
                      <Grid item xs={8}>
                        <Field
                          component={Input}
                          name="token"
                          label="Token"
                          placeholder="Enter Token"
                        />
                      </Grid>
                    )}
                    {tools.find(tool => tool.id === values.tool_id)?.auth_method
                      .id === 3 && (
                      <>
                        <Grid item xs={8}>
                          <Field
                            component={Input}
                            name="server"
                            label="Server"
                            placeholder="Enter Server Name"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={Input}
                            name="code"
                            label="Code"
                            placeholder="Enter Code"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Field
                            component={Input}
                            name="secret"
                            label="Secret"
                            placeholder="Enter Secret"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4}>
                      <FlatButton
                        width="200px"
                        variant="contained"
                        onClick={handleAPICheck}
                        isAPI="true"
                      >
                        Check API Credentials
                      </FlatButton>
                    </Grid>
                  </Grid>
                )}
                {values.intgr_level === 2 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Field
                        name="type"
                        as={TextField}
                        id="type"
                        label="Type Of Integration"
                        variant="outlined"
                        select
                        fullWidth
                        InputLabelProps={{ htmlFor: 'type', shrink: true }}
                        onChange={(e: React.ChangeEvent<{ value: number }>) => {
                          const value = e.target.value;
                          setFieldValue('type', value);
                          setSelectedIntgrType(value);
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                            height: '59px',
                          },
                        }}
                      >
                        <MenuItem value={0} style={{ display: 'none' }}>
                          <MenuItemText>Select Integration Type</MenuItemText>
                        </MenuItem>
                        <MenuItem value={1} style={MenuItemBox}>
                          <MenuItemText>Global Integration</MenuItemText>
                        </MenuItem>
                        <MenuItem value={2} style={MenuItemBox}>
                          <MenuItemText>Native Integration</MenuItemText>
                        </MenuItem>
                      </Field>
                    </Grid>
                    <Grid container paddingTop={2} paddingBottom={2}>
                      <Grid item xs={6} paddingBottom={2}>
                        <Field
                          name="client_id"
                          as={TextField}
                          id="client_id"
                          label="Choose Client"
                          variant="outlined"
                          select
                          fullWidth
                          InputLabelProps={{
                            htmlFor: 'client_id',
                            shrink: true,
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              height: '59px',
                            },
                          }}
                        >
                          <MenuItem value={0} style={{ display: 'none' }}>
                            <MenuItemText>Select Client</MenuItemText>
                          </MenuItem>
                          {Array.isArray(clientsAsRefs) &&
                            clientsAsRefs.map((client, index) => (
                              <MenuItem
                                key={index}
                                value={client.id}
                                style={MenuItemBox}
                              >
                                <MenuItemText>{client.name}</MenuItemText>
                              </MenuItem>
                            ))}
                        </Field>
                      </Grid>
                      {values.type === 1 && (
                        <Grid item xs={6} paddingLeft={2}>
                          <Field
                            name="tool_org_id"
                            as={TextField}
                            id="tool_org_id"
                            label="Choose Organization In Tool"
                            variant="outlined"
                            select
                            fullWidth
                            InputLabelProps={{
                              htmlFor: 'tool_org_id',
                              shrink: true,
                            }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                                height: '59px',
                              },
                            }}
                          >
                            <MenuItem value={0} style={{ display: 'none' }}>
                              <MenuItemText>
                                Select Organization In Tool
                              </MenuItemText>
                            </MenuItem>
                            {Array.isArray(toolOrgs) &&
                              toolOrgs.map((tool_org, index) => (
                                <MenuItem
                                  key={index}
                                  value={tool_org.id}
                                  style={MenuItemBox}
                                >
                                  <MenuItemText>{tool_org.name}</MenuItemText>
                                </MenuItem>
                              ))}
                          </Field>
                        </Grid>
                      )}
                      {values.type === 2 && (
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          alignItems="center"
                        >
                          {tools.find(tool => tool.id === values.tool_id)
                            ?.auth_method.id === 1 && (
                            <>
                              <Grid item xs={8}>
                                <Field
                                  component={Input}
                                  name="code"
                                  label="Code"
                                  placeholder="Enter Code"
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <Field
                                  component={Input}
                                  name="secret"
                                  label="Secret"
                                  placeholder="Enter Secret"
                                />
                              </Grid>
                            </>
                          )}
                          {tools.find(tool => tool.id === values.tool_id)
                            ?.auth_method.id === 2 && (
                            <Grid item xs={8}>
                              <Field
                                component={Input}
                                name="token"
                                label="Token"
                                placeholder="Enter Token"
                              />
                            </Grid>
                          )}
                          {tools.find(tool => tool.id === values.tool_id)
                            ?.auth_method.id === 3 && (
                            <>
                              <Grid item xs={8}>
                                <Field
                                  component={Input}
                                  name="server"
                                  label="Server"
                                  placeholder="Enter Server Name"
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <Field
                                  component={Input}
                                  name="code"
                                  label="Code"
                                  placeholder="Enter Code"
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <Field
                                  component={Input}
                                  name="secret"
                                  label="Secret"
                                  placeholder="Enter Secret"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item xs={4}>
                            <FlatButton
                              width="200px"
                              variant="contained"
                              onClick={handleAPICheck}
                              isAPI="true"
                            >
                              Check API Credentials
                            </FlatButton>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={8}>
                <FlatButton
                  width="200px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Create Integration
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </WrapperAddIntegrationModal>
  );
};

export default AddIntegrationModal;
