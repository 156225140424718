import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setError, setLoading } from 'reduxStore/extraReducersHelpers';
import { ILoadableState } from 'types';
import {
  IHuntressAgent,
  IHuntressGeneralStatistics,
  IHuntressIncident,
  IHuntressIncidentsRef,
  IHuntressReport,
  IHuntressReportsRef,
} from 'types/huntress.type';
import {
  retrieveHuntressAgents,
  retrieveHuntressGeneralStatistics,
  retrieveHuntressIncidents,
  retrieveHuntressIncidentsRefs,
  retrieveHuntressReports,
  retrieveHuntressReportsRefs,
} from './toolHuntressOperations';

interface IToolHuntressState extends ILoadableState {
  generalStatistics: IHuntressGeneralStatistics | null;
  agents: IHuntressAgent[];
  incidentRefs: IHuntressIncidentsRef[];
  incidents: IHuntressIncident[];
  selectedOrgName: string;
  selectedOrgId: number;
  reportRefs: IHuntressReportsRef[];
  reports: IHuntressReport[];
}

const initialState: IToolHuntressState = {
  generalStatistics: null,
  agents: [],
  incidentRefs: [],
  incidents: [],
  reportRefs: [],
  reports: [],
  selectedOrgName: '',
  selectedOrgId: 0,
  loading: false,
  error: null,
};

const toolHuntressSlice = createSlice({
  name: 'toolHuntress',
  initialState,
  reducers: {
    setSelectedOrgName(state, action: PayloadAction<string>) {
      state.selectedOrgName = action.payload;
    },
    setSelectedOrgId(state, action: PayloadAction<number>) {
      state.selectedOrgId = action.payload;
    },
    resetHuntressError(state) {
      state.error = null;
    },
    resetHuntressSelectedOrg(state) {
      state.selectedOrgId = 0;
      state.selectedOrgName = '';
    },
    resetHuntressAllData: () => initialState,
  },
  extraReducers: builder => {
    builder
      //retrieveHuntressGeneralStatistics
      .addCase(retrieveHuntressGeneralStatistics.pending, setLoading)
      .addCase(
        retrieveHuntressGeneralStatistics.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.generalStatistics = payload;
        }
      )
      .addCase(retrieveHuntressGeneralStatistics.rejected, setError)
      //retrieveHuntressAgents
      .addCase(retrieveHuntressAgents.pending, setLoading)
      .addCase(retrieveHuntressAgents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.agents = payload.agents;
      })
      .addCase(retrieveHuntressAgents.rejected, setError)
      //retrieveHuntressIncidentsRefs
      .addCase(retrieveHuntressIncidentsRefs.pending, setLoading)
      .addCase(
        retrieveHuntressIncidentsRefs.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.incidentRefs = payload;
        }
      )
      .addCase(retrieveHuntressIncidentsRefs.rejected, setError)
      //retrieveHuntressIncidents
      .addCase(retrieveHuntressIncidents.pending, setLoading)
      .addCase(retrieveHuntressIncidents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.incidents = payload.incidents;
      })
      .addCase(retrieveHuntressIncidents.rejected, setError)
      //retrieveHuntressReportsRefs
      .addCase(retrieveHuntressReportsRefs.pending, setLoading)
      .addCase(retrieveHuntressReportsRefs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.reportRefs = payload;
      })
      .addCase(retrieveHuntressReportsRefs.rejected, setError)
      //retrieveHuntressReports
      .addCase(retrieveHuntressReports.pending, setLoading)
      .addCase(retrieveHuntressReports.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.reports = payload.reports;
      })
      .addCase(retrieveHuntressReports.rejected, setError);
  },
});

export const {
  setSelectedOrgName,
  setSelectedOrgId,
  resetHuntressAllData,
  resetHuntressError,
  resetHuntressSelectedOrg,
} = toolHuntressSlice.actions;

export default toolHuntressSlice.reducer;
