//import { MSP } from 'constants/organizationsType';
import { IOrgProfileFormValues } from 'types';
import {
  IOrganizationProfileRequestBody,
  IUpdateOrganizationBody,
} from 'types/api.type';
//import { OrgTypeOption } from 'types/organizations.type';

export interface IOrganizationBodyOptions {
  logo: string;
  id?: number | null;
  mspId?: number | null;
}

export const getOrganizationRequestBody = (
  values: IOrgProfileFormValues,
  options?: IOrganizationBodyOptions
): IOrganizationProfileRequestBody | IUpdateOrganizationBody => {
  const { name, admins, street, city, state, zipCode, margin } = values;
  const { logo, id, mspId } = options || {};

  const baseBody: IOrganizationProfileRequestBody = {
    name,
    address: {
      street,
      city,
      state,
      zip_code: zipCode,
    },
    admins,
    ...(margin && { margin: +margin }),
    ...(logo && { logo }),
    ...(mspId && { msp_id: mspId }),
  };

  if (id && typeof id === 'number') {
    return { ...baseBody, id } as IUpdateOrganizationBody;
  }
  return baseBody as IOrganizationProfileRequestBody;
};

export const isServerError = (status: number) => {
  return status >= 500 && status <= 599;
};
