import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './routes.const';
import {
  SignOn,
  OrgProfile,
  AddOrUpdateTeamMember,
  AddClient,
  AddService,
  DeleteIntegration,
  DeleteClient,
  DeleteTeamMember,
  HuntressAgents,
  HuntressIncidents,
  HuntressReports,
  ClientsConfig,
  ServicesConfig,
  TeamMembersConfig,
  ClientsList,
  TeamMembersList,
  AccountSettingsView,
  BusinessSettingsView,
  UpdateClient,
} from 'views';
import {
  AuthLayoutPage,
  MainLayoutPage,
  CreateProfileLayoutPage,
  DashboardPage,
  TeamMembersPage,
  ClientsPage,
  ServicesListPage,
  HuntressLayoutPage,
  LogoLayoutPage,
} from 'pages';
import { RedirectPage, AuthHandler } from 'components';
import ProtectedProfileRoute from './ProtectedProfileRoute/ProtectedProfileRoute';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import PublicRoute from './PublicRoute/PublicRoute';
import App from 'App';
import ServicePage from 'pages/ServicePage/ServicePage';
import {
  HuntressDashboardPage,
  HuntressEDRPage,
  HuntressITDRPage,
} from 'pages/huntressPages';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <div>Not Found Page</div>,
    children: [
      {
        path: ROUTES.REDIRECT,
        element: <RedirectPage />,
      },
      {
        path: ROUTES.PROCESS_AUTH,
        element: <AuthHandler />,
      },
      {
        element: (
          <PublicRoute>
            <AuthLayoutPage />
          </PublicRoute>
        ),
        children: [{ path: ROUTES.SIGNIN, element: <SignOn /> }],
      },
      // --- create organization routes ---
      {
        element: (
          <ProtectedProfileRoute>
            <CreateProfileLayoutPage />
          </ProtectedProfileRoute>
        ),
        children: [
          { path: ROUTES.ORGANIZATION_PROFILE, element: <OrgProfile /> },
          { path: ROUTES.CLIENTS_CONFIG, element: <ClientsConfig /> },
          { path: ROUTES.SERVICES_CONFIG, element: <ServicesConfig /> },
          {
            path: ROUTES.TEAM_MEMBERS_CONFIG,
            element: <TeamMembersConfig />,
          },
        ],
      },
      // --- dashboard routes ---
      {
        element: (
          <ProtectedRoute>
            <MainLayoutPage />
          </ProtectedRoute>
        ),
        children: [
          // --- DASHBOARD ---
          {
            path: ROUTES.DASHBOARD,
            element: <DashboardPage />,
          },
          // --- TEAM MEMBERS ---
          {
            path: ROUTES.TEAM_MEMBERS,
            element: <TeamMembersPage />,
            children: [
              // --- team members list ---
              {
                path: ROUTES.TEAM_MEMBERS_LIST,
                element: <TeamMembersList />,
              },
              // --- add or update new team member ---
              {
                path: ROUTES.ADD_OR_UPDATE_TEAM_MEMBER,
                element: <AddOrUpdateTeamMember />,
              },
              // --- delete team member ---
              {
                path: ROUTES.DELETE_TEAM_MEMBER,
                element: <DeleteTeamMember />,
              },
            ],
          },
          // --- CLIENTS ---
          {
            path: ROUTES.CLIENTS,
            element: <ClientsPage />,
            children: [
              // --- clients list ---
              {
                path: ROUTES.CLIENTS_LIST,
                element: <ClientsList />,
              },
              // --- add new client ---
              {
                path: ROUTES.ADD_CLIENT,
                element: <AddClient />,
              },
              // --- update client ---
              {
                path: ROUTES.UPDATE_CLIENT,
                element: <UpdateClient />,
              },
              // --- delete client ---
              {
                path: ROUTES.DELETE_CLIENT,
                element: <DeleteClient />,
              },
            ],
          },
          // --- SERVICES ---
          {
            path: ROUTES.SERVICES,
            element: <ServicesListPage />,
            children: [
              // --- create service integration ---
              {
                path: ROUTES.ADD_SERVICE,
                element: <AddService />,
              },
              // --- delete service integration ---
              {
                path: ROUTES.DELETE_INTEGRATION,
                element: <DeleteIntegration />,
              },
              // --- HUNTRESS PAGES ---
              {
                element: <HuntressLayoutPage />,
                children: [
                  {
                    path: ROUTES.HUNTRESS_DASHBOARD,
                    element: <HuntressDashboardPage />,
                    children: [
                      {
                        path: ROUTES.HUNTRESS_DASHBOARD_AGENTS,
                        element: <HuntressAgents />,
                      },
                      {
                        path: ROUTES.HUNTRESS_DASHBOARD_INCIDENTS,
                        element: <HuntressIncidents />,
                      },
                      {
                        path: ROUTES.HUNTRESS_DASHBOARD_REPORTS,
                        element: <HuntressReports />,
                      },
                    ],
                  },
                  {
                    path: ROUTES.HUNTRESS_EDR,
                    element: <HuntressEDRPage />,
                  },
                  {
                    path: ROUTES.HUNTRESS_ITDR,
                    element: <HuntressITDRPage />,
                  },
                ],
              },
            ],
          },

          // --- service page ---
          {
            path: ROUTES.SERVICE_PAGE,
            element: <ServicePage />,
          },
          // --- tickets ---
          {
            path: ROUTES.TICKETS,
            element: <div>tickets</div>,
          },
          // --- audit ogs ---
          {
            path: ROUTES.AUDIT_LOGS,
            element: <div>audit logs</div>,
          },
        ],
      },
      {
        element: (
          <ProtectedRoute>
            <LogoLayoutPage />
          </ProtectedRoute>
        ),
        children: [
          // --- Business Settings ---
          {
            path: ROUTES.BUSINESS_SETTINGS,
            element: <BusinessSettingsView />,
          },
          // --- Account Settings ---
          {
            path: ROUTES.ACCOUNT_SETTINGS,
            element: <AccountSettingsView />,
          },
        ],
      },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
