import { useState } from 'react';
import {
  StyledActionBlock,
  StyledTableContainer,
  StyledTypography,
  StyledWrapperTitle,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  WrapperTable,
  LogoBox,
} from './TableBasis.styled';
import { TableBody, Table, IconButton, Checkbox, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconEdit,
  IconGlass,
  PositiveNegativeNeutralButton,
  SelectActions,
} from 'ui';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ITableColumn, ITableRow } from 'types';
import { IUserType } from 'types/organizations.type';
import { getFormatCellValue } from 'utils';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useAppDispatch } from 'hooks';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import {
  addElementToDeleteList,
  removeElementFromDeleteList,
  resetDeleteData,
  setDeleteDataType,
  setDeleteList,
} from 'reduxStore/organization/organizationSlice';

interface ITableBasisProps {
  maxheight?: string;
  title?: string;
  addAction?: 'true';
  icon?: 'edit' | 'remove' | 'check' | 'switch' | 'view';
  isCheckbox?: boolean;
  isWarning?: boolean;
  showPictures?: boolean;
  enabledSwitchFlag?: (row: ITableRow) => boolean;
  onClick?: (item: ITableRow) => void;
  onClickShowMore?: () => void;
  onChangeSwitch?: (item: IUserType) => void;

  //Do not support connection to selected elements for custom handleAdd or handleDelete
  handleAdd?: () => void;
  handleDelete?: () => void;
  tableData: ITableRow[];
  columns: ITableColumn[];
}

const TableBasis = ({
  maxheight,
  title,
  addAction,
  icon,
  isCheckbox,
  isWarning,
  showPictures,
  onClick,
  handleAdd,
  handleDelete,
  tableData,
  columns,
}: ITableBasisProps) => {
  const dispatch = useAppDispatch();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [checkboxStatus, setCheckboxStatus] = useState(isCheckbox);
  const [picturesStatus, setPictureStatus] = useState(showPictures);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const columnsWithoutLogoAndId = columns.filter(
    column => column.key !== 'icon' && column.key !== 'id'
  );

  const cellValue = (rowData: ITableRow, columnData: ITableColumn) => {
    const value = rowData[columnData.key]?.toString() || '-';
    return getFormatCellValue(value, columnData.format);
  };

  const onChange = (action: string) => {
    setSelectedAction(action);
    setPictureStatus(
      action === 'Delete' && !handleDelete ? false : showPictures
    );
    setCheckboxStatus(action === 'Delete' && !handleDelete && true);
  };

  const onCancel = () => {
    setSelectedAction('');
    setSelectedRows([]);
    setPictureStatus(showPictures);
    setCheckboxStatus(isCheckbox);
    dispatch(resetDeleteData());
  };

  const onDelete = () => {
    if (handleDelete) {
      handleDelete();
      return;
    }

    dispatch(
      setDeleteDataType(
        title === 'Clients' || title === 'Team Members' ? title : null
      )
    );
    dispatch(
      setModalProps({
        radius: '23px',
        title: `Are you sure you want to delete following ${title?.toLowerCase()}:`,
      })
    );
    dispatch(openModal());
    dispatch(setContent('MassDeletionConfirm'));
    setSelectedRows([]);
    setSelectedAction('');
    setPictureStatus(showPictures);
    setCheckboxStatus(isCheckbox);
  };

  const handleSelectAllCheckbox = () => {
    if (selectedRows.length === tableData?.length) {
      setSelectedRows([]);
      dispatch(resetDeleteData());
    } else {
      const allRows = tableData ? tableData.map((_, index) => index) : [];
      const allIds = tableData
        ? tableData
            .map(element =>
              'id' in element && typeof element.id === 'number' && element.id
                ? element.id
                : null
            )
            .filter((id): id is number => id !== null)
        : [];
      setSelectedRows(allRows);
      dispatch(setDeleteList(allIds));
    }
  };

  const handleCheckboxClick = (id: number, index: number) => {
    const newSelected = selectedRows.includes(index)
      ? selectedRows.filter(i => i !== index)
      : [...selectedRows, index];

    setSelectedRows(newSelected);
    selectedRows.includes(index)
      ? dispatch(removeElementFromDeleteList(id))
      : dispatch(addElementToDeleteList(id));
  };

  const handleClickIcon = (item: ITableRow) => {
    onClick?.(item);
  };

  const handleClickRow = (row: ITableRow) => {
    !checkboxStatus && onClick?.(row);
  };

  return (
    <StyledTableContainer
      iswarning={isWarning ? 'true' : undefined}
      maxheight={maxheight}
    >
      {(addAction || title) && (
        <StyledWrapperTitle>
          <StyledTypography>{title}</StyledTypography>
          {addAction && (
            <StyledActionBlock>
              {selectedAction !== '' && (
                <PositiveNegativeNeutralButton
                  onClick={onCancel}
                  width="87px"
                  height="27px"
                  borderradius="23px"
                >
                  Cancel
                </PositiveNegativeNeutralButton>
              )}
              <SelectActions
                name="Edit"
                onAdd={handleAdd}
                onDelete={onDelete}
                actionState={selectedAction}
                onChange={onChange}
              />
            </StyledActionBlock>
          )}
        </StyledWrapperTitle>
      )}
      <WrapperTable>
        <Table>
          <StyledTableHead>
            <StyledTableRow
              ischeckbox={checkboxStatus || picturesStatus ? 'true' : undefined}
              isicon={icon && 'true'}
            >
              {(checkboxStatus || picturesStatus) && (
                <StyledTableCell padding="checkbox">
                  {!picturesStatus && (
                    <Checkbox
                      checked={
                        tableData?.length > 0 &&
                        selectedRows.length === tableData.length
                      }
                      onChange={handleSelectAllCheckbox}
                      disabled={!columns.find(column => column.key === 'id')}
                    />
                  )}
                </StyledTableCell>
              )}

              {columnsWithoutLogoAndId.map(column => (
                <StyledTableCell key={column.label}>
                  {column.label}
                </StyledTableCell>
              ))}
              {icon && (
                <StyledTableCell>
                  {icon === 'edit'
                    ? 'Edit'
                    : icon === 'remove'
                      ? 'Remove'
                      : icon === 'check'
                        ? 'Check Activity'
                        : icon === 'switch'
                          ? 'Administrator'
                          : 'View'}
                </StyledTableCell>
              )}
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            {tableData.map((rowData, index) => (
              <StyledTableRow
                ischeckbox={
                  checkboxStatus || picturesStatus ? 'true' : undefined
                }
                isicon={icon && 'true'}
                key={index}
                selected={selectedRows.indexOf(index) !== -1}
                onClick={() => handleClickRow(rowData)}
              >
                {(checkboxStatus || picturesStatus) && (
                  <StyledTableCell padding="checkbox">
                    {!picturesStatus ? (
                      <Checkbox
                        checked={selectedRows.indexOf(index) !== -1}
                        onChange={() => {
                          if (
                            'id' in rowData &&
                            typeof rowData.id === 'number'
                          ) {
                            handleCheckboxClick(rowData.id, index);
                          }
                        }}
                        disabled={
                          !('id' in rowData && typeof rowData.id === 'number')
                        }
                      />
                    ) : rowData.icon ? (
                      <Box width="24px" height="24px">
                        <LogoBox
                          key={index}
                          src={
                            typeof rowData.icon === 'string' ? rowData.icon : ''
                          }
                        />
                      </Box>
                    ) : (
                      <AccountCircleOutlinedIcon
                        key={index}
                        fontSize="medium"
                      />
                    )}
                  </StyledTableCell>
                )}

                {columnsWithoutLogoAndId.map(column => (
                  <StyledTableCell key={column.label}>
                    {cellValue(rowData, column)}
                  </StyledTableCell>
                ))}
                {icon && (
                  <StyledTableCell>
                    <IconButton onClick={() => handleClickIcon(rowData)}>
                      {icon === 'edit' ? (
                        <IconEdit />
                      ) : icon === 'remove' ? (
                        <CloseIcon color="secondary" />
                      ) : icon === 'check' ? (
                        <RemoveRedEyeIcon color="secondary" />
                      ) : (
                        <IconGlass />
                      )}
                    </IconButton>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </WrapperTable>
    </StyledTableContainer>
  );
};

export default TableBasis;
