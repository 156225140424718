import {
  ActionMenuItem,
  ActionsDivider,
  ActionsWrapper,
  MenuProps,
  SelectedOption,
  Selection,
} from './SelectActions.styled';
import { ACTION_SELECTOR } from 'constants/selectActions';
import { ISelectItemProps } from 'types/selectItem.type';
import { PositiveNegativeNeutralButton } from 'ui';

interface ISelectAction {
  name: string;
  onAdd?: () => void;
  onDelete?: () => void;
  onChange?: (name: string) => void;
  actionState: string;
}

const SelectAction = ({
  name,
  onAdd,
  onDelete,
  onChange,
  actionState,
}: ISelectAction) => {
  const handleChange = () => {
    if (actionState === 'Add') {
      onAdd && onAdd();
    } else if (actionState === 'Delete') {
      onDelete && onDelete();
    }
  };

  return (
    <ActionsWrapper>
      {actionState === '' ? (
        <Selection
          displayEmpty={true}
          value={actionState}
          renderValue={() => {
            const selectedOption = ACTION_SELECTOR.find(
              action => action.title === actionState
            );
            return selectedOption ? (
              <SelectedOption>
                {selectedOption.title} {selectedOption.icon}
              </SelectedOption>
            ) : (
              name
            );
          }}
          //onChange={handleChange}
          MenuProps={MenuProps}
        >
          {ACTION_SELECTOR.map(
            (
              variantItem: ISelectItemProps,
              index: number,
              array: ISelectItemProps[]
            ) => [
              <ActionMenuItem
                key={`${variantItem.title}-${index}`}
                value={variantItem.title}
                onClick={() => onChange && onChange(variantItem.title)}
              >
                <>
                  {variantItem.title} {variantItem.icon}
                </>
              </ActionMenuItem>,
              index !== array.length - 1 && (
                <ActionsDivider key={`divider-${index}`} />
              ),
            ]
          )}
        </Selection>
      ) : (
        <PositiveNegativeNeutralButton
          onClick={handleChange}
          width="87px"
          height="27px"
          state={actionState === 'Add' ? 'Positive' : 'Negative'}
          borderradius="23px"
        >
          {actionState}
        </PositiveNegativeNeutralButton>
      )}
    </ActionsWrapper>
  );
};

export default SelectAction;
