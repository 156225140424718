import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER, ISTOK_WEB } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

export const WrapperMassDeletionConfirm = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '448px',
  height: '447px',
  flexDirection: 'column',
  gap: '20px',
  padding: '21px 54px 29px 54px',
}));

export const HeaderPart = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '100%',
  height: 'fit-content',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '6px',
}));

export const WrapperElementsToDelete = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '100%',
  height: '213px',
  overflowY: 'auto',
  flexDirection: 'column',
  gap: '11px',
}));

export const WrapperButtons = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  width: '100%',
  height: 'fit-content',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.secondaryBlack,
  fontFamily: INTER,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '133.1%',
  letterSpacing: '0%',
  textAlign: 'center',
}));

export const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  position: 'absolute',
  top: '-50px',
  left: '50%',
  transform: 'translateX(-50%)',
  color: theme.palette.error.main,
  fontFamily: ISTOK_WEB,
  fontSize: '16px',
  lineHeight: '150%',
  fontWeight: '400',
  textAlign: 'center',
  marginTop: '10px',
  letterSpacing: '-0.352px',
}));
