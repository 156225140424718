import { Wrapper } from './TeamMembersPage.styled';
import { Navigate, Outlet, useLocation } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { HeaderWithSearch } from 'ui';

const TeamMembersPage = () => {
  const location = useLocation();

  // Redirect if the user visits /team_members directly
  if (location.pathname === ROUTES.TEAM_MEMBERS) {
    return <Navigate to={ROUTES.TEAM_MEMBERS_LIST} replace />;
  }
  return (
    <Wrapper>
      <HeaderWithSearch />
      <Outlet />
    </Wrapper>
  );
};

export default TeamMembersPage;
