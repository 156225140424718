import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setError, setLoading } from '../extraReducersHelpers';
import {
  IAccountInfo,
  IAdminInfo,
  IClient,
  IOrganization,
  IOrganizationByID,
  IUserOrganizations,
} from 'types/organizations.type';
import {
  retrieveAccountInfo,
  retrieveOrganization,
  updateOrganization,
} from './organizationOperations';
import { ILoadableState } from 'types/store.type';
import { ICreateContactBody } from 'types/api.type';

interface IOrganizationState extends ILoadableState {
  admin: IAdminInfo | null;
  userOrganizations: IUserOrganizations | null;
  mainOrganization: IOrganization | null;
  clients: IClient[];
  selectedOrganization: IOrganizationByID | null;
  teamMemberToUpdate: ICreateContactBody | null;
  deleteList: number[];
  deleteDataType: 'Clients' | 'Team Members' | null;
}

const initialState: IOrganizationState = {
  admin: null,
  userOrganizations: null,
  mainOrganization: null,
  clients: [],
  selectedOrganization: null,
  teamMemberToUpdate: null,
  deleteList: [],
  deleteDataType: null,
  loading: false,
  error: null,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganization: () => initialState,
    resetSelectedOrganization(state) {
      state.selectedOrganization = initialState.selectedOrganization;
    },
    setTeamMemberToUpdate(state, action: PayloadAction<ICreateContactBody>) {
      state.teamMemberToUpdate = action.payload;
    },
    resetTeamMemberToUpdate(state) {
      state.teamMemberToUpdate = initialState.teamMemberToUpdate;
    },
    setDeleteList(state, action: PayloadAction<number[]>) {
      state.deleteList = action.payload;
    },
    setDeleteDataType(
      state,
      action: PayloadAction<'Clients' | 'Team Members' | null>
    ) {
      state.deleteDataType = action.payload;
    },
    addElementToDeleteList(state, action: PayloadAction<number>) {
      state.deleteList.push(action.payload);
    },
    removeElementFromDeleteList(state, action: PayloadAction<number>) {
      state.deleteList = state.deleteList.filter(
        (element: number) => element !== action.payload
      );
    },
    resetDeleteData(state) {
      state.deleteList = initialState.deleteList;
      state.deleteDataType = initialState.deleteDataType;
    },
  },
  extraReducers: builder => {
    builder
      // retrieveAccountInfo
      .addCase(retrieveAccountInfo.pending, setLoading)
      .addCase(
        retrieveAccountInfo.fulfilled,
        (state, { payload }: PayloadAction<IAccountInfo>) => {
          state.loading = false;
          state.admin = payload.admin;
          state.mainOrganization = payload.home;
        }
      )
      .addCase(retrieveAccountInfo.rejected, setError)
      // retrieveOrganization
      .addCase(retrieveOrganization.pending, setLoading)
      .addCase(
        retrieveOrganization.fulfilled,
        (state, { payload }: PayloadAction<IOrganizationByID>) => {
          state.loading = false;
          state.selectedOrganization = payload;
        }
      )
      .addCase(retrieveOrganization.rejected, setError)
      // updateOrganization
      .addCase(updateOrganization.pending, setLoading)
      .addCase(updateOrganization.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateOrganization.rejected, setError);
  },
});

export const {
  resetOrganization,
  resetSelectedOrganization,
  setTeamMemberToUpdate,
  resetTeamMemberToUpdate,
  setDeleteList,
  setDeleteDataType,
  addElementToDeleteList,
  removeElementFromDeleteList,
  resetDeleteData,
} = organizationSlice.actions;

export default organizationSlice.reducer;
