import { GeneralStatistics, HeaderWithSearch, TableBasis } from 'ui';
import { WrapperGeneral, MenuInfoBoxes } from './DashboardPage.styled';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getClients,
  getContacts,
  getMSPRoles,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect, useState } from 'react';
import {
  retrieveClients,
  retrieveContacts,
  retrieveMSPRoles,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getAdminInfo,
  getMainOrganizationID,
} from 'reduxStore/organization/organizationSelectors';
import {
  IClientStatisticBody,
  ICreateContactBody,
  IReceiveContactBody,
  ITool,
} from 'types/api.type';
import {
  CLIENTS_TABLE,
  SERVICES_TABLE,
  TEAM_MEMBERS_TABLE,
} from 'constants/tablesHead';
import { setTeamMemberToUpdate } from 'reduxStore/organization/organizationSlice';
import { retrieveOrganization } from 'reduxStore/organization/organizationOperations';

interface IMenuOptions {
  onClickAdd: () => void;
  onClickViewAll: () => void;
  title: 'Team Members' | 'Clients' | 'Connected Services';
  data: ITool[] | IClientStatisticBody[] | IReceiveContactBody[] | null;
}

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tools = useAppSelector(getTools);
  const clients = useAppSelector(getClients);
  const teamMembers = useAppSelector(getContacts);
  const mspRoles = useAppSelector(getMSPRoles);
  const mainOrgId = useAppSelector(getMainOrganizationID);
  const admin = useAppSelector(getAdminInfo);

  const [selectedElement, setSelectedElement] = useState<
    'Team Members' | 'Clients' | 'Connected Services'
  >('Clients');

  const connectedTools = tools.filter(
    tool =>
      (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
      (tool.clients_count !== null && tool.clients_count !== 0)
  );

  const reformatedClients = Array.isArray(clients)
    ? clients.map(client => {
        return {
          name: client.name,
          dev_count: client.devices_count,
          admin_count: client.admins_count,
          alert_count: client.alerts_count,
          tool_count: client.tools_count,
          icon: client.icon || '',
          id: client.id,
        };
      })
    : [];

  const reformatedContacts =
    teamMembers?.internal?.map(teamMember => {
      return {
        first_name: teamMember.first_name ?? 'Missing',
        last_name: teamMember.last_name ?? 'Missing',
        phone: teamMember.phone,
        email: teamMember.email,
        role: teamMember.role?.name,
        icon: teamMember.icon || '',
        id: teamMember.id,
      };
    }) || [];

  const formattedServices = Array.isArray(connectedTools)
    ? connectedTools.map(connectedTool => {
        const connectionStatus = 'Connected';
        return {
          service_name: connectedTool.name,
          client_count: connectedTool.clients_count,
          devices: connectedTool.devices_count,
          status: connectedTool.status?.name,
          is_connected: connectionStatus,
          icon: connectedTool.icon || '',
        };
      })
    : [];

  const options: IMenuOptions[] = [
    {
      onClickAdd: () => handlePageRedirection(ROUTES.ADD_CLIENT),
      onClickViewAll: () => handlePageRedirection(ROUTES.CLIENTS),
      title: 'Clients',
      data: clients,
    },
    {
      onClickAdd: () => handlePageRedirection(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER),
      onClickViewAll: () => handlePageRedirection(ROUTES.TEAM_MEMBERS),
      title: 'Team Members',
      data: teamMembers?.internal ?? [],
    },
    {
      onClickAdd: () => handlePageRedirection(ROUTES.ADD_SERVICE),
      onClickViewAll: () => handlePageRedirection(ROUTES.SERVICES),
      title: 'Connected Services',
      data: connectedTools,
    },
  ];

  useEffect(() => {
    dispatch(retrieveClients(null));
    dispatch(retrieveTools(null));
    dispatch(retrieveContacts(mainOrgId));
    dispatch(retrieveMSPRoles(null));
  }, [dispatch, mainOrgId]);

  const handlePageRedirection = (route: any) => {
    navigate(route);
  };

  const handleAdd = (
    option: 'Team Members' | 'Clients' | 'Connected Services'
  ) => {
    switch (option) {
      case 'Team Members':
        navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
        break;
      case 'Clients':
        navigate(ROUTES.ADD_CLIENT);
        break;
      case 'Connected Services':
        navigate(ROUTES.ADD_SERVICE);
        break;
      default:
        return;
    }
  };
  const handleDelete = (
    option: 'Team Members' | 'Clients' | 'Connected Services'
  ) => {
    switch (option) {
      case 'Team Members':
        navigate(ROUTES.DELETE_TEAM_MEMBER);
        break;
      case 'Clients':
        navigate(ROUTES.DELETE_CLIENT);
        break;
      case 'Connected Services':
        navigate(ROUTES.DELETE_INTEGRATION);
        break;
      default:
        return;
    }
  };

  const handleUpdateTeamMember = (row: any) => {
    if (row.email === admin?.email) {
      navigate(ROUTES.ACCOUNT_SETTINGS);
      return;
    }
    const teamMemberToUpdate: ICreateContactBody = {
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      phone: row.phone,
      org_id: mainOrgId,
      role_id: mspRoles.find(role => role.name === row.role)?.id ?? 0,
      icon: row.icon,
    };
    dispatch(setTeamMemberToUpdate(teamMemberToUpdate));
    navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
  };

  const handleUpdateClient = async (row: any) => {
    try {
      await dispatch(retrieveOrganization(row.id)).unwrap();
      navigate(ROUTES.UPDATE_CLIENT);
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperGeneral>
      <HeaderWithSearch />
      <MenuInfoBoxes>
        {options.map((option, index) => (
          <span
            role="button"
            key={index}
            onClick={() => setSelectedElement(option.title)}
            tabIndex={0}
          >
            <GeneralStatistics
              onClickAdd={option.onClickAdd}
              onClickViewAll={option.onClickViewAll}
              width="25vw"
              title={option.title}
              data={option.data}
              isactive={selectedElement === option.title ? 'true' : 'false'}
            />
          </span>
        ))}
      </MenuInfoBoxes>
      {selectedElement === 'Clients' && (
        <TableBasis
          tableData={reformatedClients}
          columns={CLIENTS_TABLE}
          title={selectedElement}
          icon="edit"
          showPictures={true}
          addAction="true"
          onClick={handleUpdateClient}
          handleAdd={() => handleAdd(selectedElement)}
        />
      )}

      {selectedElement === 'Team Members' && (
        <TableBasis
          tableData={reformatedContacts}
          columns={TEAM_MEMBERS_TABLE}
          title={selectedElement}
          icon="edit"
          addAction="true"
          showPictures={true}
          onClick={handleUpdateTeamMember}
          handleAdd={() => handleAdd(selectedElement)}
          handleDelete={() => handleDelete(selectedElement)}
        />
      )}

      {selectedElement === 'Connected Services' && (
        <TableBasis
          tableData={formattedServices}
          columns={SERVICES_TABLE}
          title={selectedElement}
          addAction="true"
          showPictures={true}
          handleAdd={() => handleAdd(selectedElement)}
          handleDelete={() => handleDelete(selectedElement)}
        />
      )}
    </WrapperGeneral>
  );
};

export default DashboardPage;
