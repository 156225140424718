import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { INTER } from 'constants/fonts';
import { theme } from 'theme';
import { COLORS } from 'theme/colors.const';

interface ICircleProps extends BoxProps {
  color: string;
  width: string;
  height: string;
}

export const WrapperDataBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '15px 20px',
  borderRadius: '20px',
  border: `2px solid ${COLORS.gray}`,
  background: theme.palette.common.white,
}));

export const WrapperGraph = styled(Box)<BoxProps>(() => ({
  height: '52px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const WrapperElementsWithGraph = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'space-between',
}));

export const WrapperElementsWithoutGraph = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const WrapperOneElementWithGraph = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'calc(50% - 20px)',
  gap: '20px',

  '& > div': {
    flex: 1, // Equal space for each child
    maxWidth: 'calc(50% - 20px)',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
}));

export const WrapperOneElementWithoutGraph = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px',
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.528px',
}));

export const ElementTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const ElementAmount = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-2.2%',
}));

export const TotalAmount = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '46px',
  fontWeight: 600,
  lineHeight: '42px',
  letterSpacing: '-0.528px',
}));

export const Circle = styled(Box)<ICircleProps>(({ color, width, height }) => ({
  width: width,
  height: height,
  minWidth: width,
  minHeight: height,
  borderRadius: '50%',
  background: color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
