import React, { useState } from 'react';
import { HoverText, StyledRoundButton } from './RoundButton.styled';
import { FormikValues, useFormikContext } from 'formik';

interface IRoundButtonProps {
  variant?: 'contained' | 'outlined';
  type?: 'button' | 'submit' | 'reset';
  width?: string;
  height?: string;
  isDisabled?: boolean;
  isAPI?: 'true';
  setExpansion?: 'true';
  needsExpansion?: boolean;
  expansionText?: string;
  onClick?: (values?: any) => void;
  children?: React.ReactNode | string;
}

const RoundButton = ({
  variant,
  type,
  isAPI,
  width,
  height,
  isDisabled,
  setExpansion,
  needsExpansion,
  expansionText,
  onClick,
  children,
}: IRoundButtonProps) => {
  const formikContext = useFormikContext<FormikValues>();
  const onClickHandler = () => {
    if (isAPI && formikContext) {
      onClick?.(formikContext.values);
    } else {
      onClick?.();
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledRoundButton
      variant={variant}
      type={type}
      height={height}
      width={width}
      disabled={isDisabled}
      needsexpansion={
        needsExpansion === true || setExpansion ? 'true' : undefined
      }
      ishovered={isHovered === true || setExpansion ? 'true' : undefined}
      onMouseEnter={() => {
        setExpansion ?? setIsHovered(true);
      }}
      onMouseLeave={() => {
        setExpansion ?? setIsHovered(false);
      }}
      onClick={onClickHandler}
    >
      {children}
      {(isHovered || setExpansion) && <HoverText>{expansionText}</HoverText>}
    </StyledRoundButton>
  );
};

export default RoundButton;
