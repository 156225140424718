import { Outlet, useLocation } from 'react-router';
import { Stack } from '@mui/material';
import { LogicallyContainer } from './MainLayoutPage.styled';
import { SideBarLogically } from 'views';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetHuntressAllData } from 'reduxStore/toolHuntress/toolHuntressSlice';
import { ROUTES } from 'routes/routes.const';
import { resetTeamMemberToUpdate } from 'reduxStore/organization/organizationSlice';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';

const MainLayoutPage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  //to check previous path for possible need of resets
  const [prevPath, setPrevPath] = useState<string | null>(null);

  const mainOrgId = useAppSelector(getMainOrganizationID);

  useEffect(() => {
    // reset Huntress data if user left services page and child pages
    if (
      !location.pathname.startsWith(ROUTES.SERVICES) &&
      prevPath?.startsWith(ROUTES.SERVICES)
    ) {
      dispatch(resetHuntressAllData());
    }
    // reset TeamMemberToUpdate if user left AddOrUpdateTeamMember
    if (
      !location.pathname.endsWith(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER) &&
      prevPath?.endsWith(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER)
    ) {
      dispatch(resetTeamMemberToUpdate());
    }

    return () => {
      //save path
      setPrevPath(location.pathname);
    };
  }, [dispatch, location, mainOrgId, prevPath]);

  return (
    <Stack direction="row" width="100vw">
      <SideBarLogically />
      <LogicallyContainer>
        <Outlet />
      </LogicallyContainer>
    </Stack>
  );
};

export default MainLayoutPage;
