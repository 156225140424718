import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography, TypographyProps } from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IBackgroundBoxProps extends BoxProps {
  image: string;
}

interface IBoxProps extends BoxProps {
  isimageremoved?: 'true';
}

export const ContainerSignIn = styled(Box)<BoxProps>(() => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '10vw',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor: COLORS.blue100,
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '48px',
  letterSpacing: '-0.701px',
}));

export const SubTitle = styled(Typography)<TypographyProps>(() => ({
  color: COLORS.black,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.317px',
}));

export const ContentBox = styled(Box)<IBoxProps>(({ isimageremoved }) => ({
  width: isimageremoved ? '90%' : '50%',
  height: '90%',
  padding: '2.5% 5% 5% 5%',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  backgroundColor: COLORS.white,
  borderRadius: '20px',
}));

export const ErrorText = styled(Typography)<TypographyProps>(() => ({
  paddingBottom: '10px',
  maxWidth: '400px',
  wordBreak: 'break-all',
  color: COLORS.authError,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 800,
  lineHeight: '150%',
  letterSpacing: '-0.352px',
}));

export const ImgBox = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  minWidth: 'fit-content',
  backgroundColor: COLORS.grayBackgroundPrimary,
}));

export const BackgroundBox = styled(Box)<IBackgroundBoxProps>(({ image }) => ({
  backgroundImage: `url(${image})`,
  position: 'absolute',
  right: 0,
  zIndex: 2,
  maxWidth: '60%',
  width: '30%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left',
  backgroundSize: 'cover',
}));
