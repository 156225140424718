import { Box } from '@mui/material';
import {
  Circle,
  ElementAmount,
  ElementTitle,
  Title,
  TotalAmount,
  WrapperDataBox,
  WrapperElementsWithGraph,
  WrapperElementsWithoutGraph,
  WrapperGraph,
  WrapperOneElementWithGraph,
  WrapperOneElementWithoutGraph,
} from './DataBox.styled';
import { BarChart, Bar, XAxis, ResponsiveContainer, Cell } from 'recharts';

interface IDataBoxProps {
  title: string;
  data: { title: string; amount: number; color: string }[];
  width?: string;
  height?: string;
  setSumOfElements?: number;
  needsGraph?: 'true';
}

const DataBox = ({
  title,
  data,
  width,
  height,
  needsGraph,
  setSumOfElements,
}: IDataBoxProps) => {
  const totalAmount = data.reduce((sum, item) => sum + item.amount, 0);
  return (
    <WrapperDataBox width={width} height={height}>
      <Title>{title}</Title>
      <WrapperGraph>
        <TotalAmount>
          {setSumOfElements ? setSumOfElements : totalAmount}
        </TotalAmount>
        {needsGraph ? (
          <ResponsiveContainer width="50%" height="100%">
            <BarChart layout="vertical" data={data} barCategoryGap="20%">
              <XAxis type="number" hide />
              <Bar dataKey="amount" barSize={10} radius={10} maxBarSize={10}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <></>
        )}
      </WrapperGraph>
      {needsGraph ? (
        <WrapperElementsWithGraph>
          {data.map((entry, index) => (
            <WrapperOneElementWithGraph key={index}>
              <Box
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
              >
                <Circle color={entry.color} width="12px" height="12px" />
                <ElementTitle>{entry.title}</ElementTitle>
              </Box>
              <ElementAmount>{entry.amount}</ElementAmount>
            </WrapperOneElementWithGraph>
          ))}
        </WrapperElementsWithGraph>
      ) : (
        <WrapperElementsWithoutGraph>
          {data.map((entry, index) => (
            <WrapperOneElementWithoutGraph key={index}>
              <Circle color={entry.color} width="58px" height="58px">
                <ElementAmount>{entry.amount}</ElementAmount>
              </Circle>
              <ElementTitle>{entry.title}</ElementTitle>
            </WrapperOneElementWithoutGraph>
          ))}
        </WrapperElementsWithoutGraph>
      )}
    </WrapperDataBox>
  );
};

export default DataBox;
