import { TEAM_MEMBERS_TABLE } from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  retrieveContacts,
  retrieveMSPRoles,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getContacts,
  getMSPRoles,
} from 'reduxStore/createProfile/createProfileSelectors';
import {
  getAdminInfo,
  getMainOrganizationID,
} from 'reduxStore/organization/organizationSelectors';
import { setTeamMemberToUpdate } from 'reduxStore/organization/organizationSlice';
import { ROUTES } from 'routes/routes.const';
import { ICreateContactBody } from 'types/api.type';
import { TableBasis } from 'ui';

const TeamMembersList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const contacts = useAppSelector(getContacts);
  const mspRoles = useAppSelector(getMSPRoles);
  const organizationId = useAppSelector(getMainOrganizationID);
  const admin = useAppSelector(getAdminInfo);

  const handleAddTeamMember = () => {
    navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
  };

  const handleDeleteTeamMember = () => {
    navigate(ROUTES.DELETE_TEAM_MEMBER);
  };

  const handleUpdateTeamMember = (row: any) => {
    if (row.email === admin?.email) {
      navigate(ROUTES.ACCOUNT_SETTINGS);
      return;
    }

    const teamMemberToUpdate: ICreateContactBody = {
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      phone: row.phone,
      org_id: organizationId,
      role_id: mspRoles.find(role => role.name === row.role)?.id ?? 0,
      icon: row.icon,
    };
    dispatch(setTeamMemberToUpdate(teamMemberToUpdate));
    navigate(ROUTES.ADD_OR_UPDATE_TEAM_MEMBER);
  };

  useEffect(() => {
    dispatch(retrieveContacts(organizationId));
    dispatch(retrieveMSPRoles(null));
  }, [dispatch, organizationId]);

  const reformatedContacts =
    contacts?.internal?.map(contact => {
      return {
        first_name: contact.first_name ?? 'Missing',
        last_name: contact.last_name ?? 'Missing',
        phone: contact.phone,
        email: contact.email,
        role: contact.role?.name,
        icon: contact.icon || '',
        id: contact.id,
      };
    }) || [];

  return (
    <TableBasis
      tableData={reformatedContacts}
      columns={TEAM_MEMBERS_TABLE}
      title="Team Members"
      icon="edit"
      addAction="true"
      showPictures={true}
      handleAdd={handleAddTeamMember}
      handleDelete={handleDeleteTeamMember}
      onClick={handleUpdateTeamMember}
    />
  );
};

export default TeamMembersList;
